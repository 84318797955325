import { MouseTransition, TouchTransition } from "dnd-multi-backend";
import { useRef } from "react";
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd-multi-backend";
import { TouchBackend } from "react-dnd-touch-backend";

interface CardProps {
  bg?: string,
  className?: string,
  children: JSX.Element,
}

export const Card = (props: CardProps) => {
  const bg = props.bg || 'bg-gray-50';
  return (
    <div className={`px-4 py-2 mx-auto max-w-screen-lg ${bg} my-2 ${props.className}`}>
      {props.children}
    </div>
  );
}

interface BasicDragProps {
  descriptor: string,
  move: Function,
}

interface DragListProps extends BasicDragProps {
  list: Array<IDedObject>,
}

interface DragItem {
  index: number
  id: string
  type: string
}

export const DragList = ({ descriptor, list, move }: DragListProps) => {
  const dndOptions = {
    backends: [
      {
        id: 'touch',
        backend: TouchBackend,
        options: { enableMouseEvents: true },
        preview: true,
        transition: TouchTransition,
      },
      {
        id: 'html5',
        backend: HTML5Backend,
        preview: true,
        transition: MouseTransition,
      },
    ],
  }

  return (
    <DndProvider options={dndOptions}>
      <div>
        {list.map((element, idx: number) => (
          <DnDBox key={element.id} descriptor={descriptor} element={element} idx={idx} move={move} />
        ))}
      </div>
    </DndProvider>
  );
}

interface DragBoxProps extends BasicDragProps {
  element: IDedObject,
  idx: number,
}

// Taken from https://react-dnd.github.io/react-dnd/examples/sortable/simple
export const DnDBox = ({ descriptor, element, idx, move }: DragBoxProps) => {
  const type = "DnDBox";
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = idx;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      move(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  })

  const id = element?.id;
  const [{ isDragging }, drag] = useDrag({
    type: type,
    item: () => ({ id, index: idx }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref));

  return (
    <div className="flex">
      <div className="m-auto text-center w-10 font-semibold text-lg">{idx + 1}:</div>
      <div
        ref={ref}
        data-handler-id={handlerId}
        className="flex-grow border border-gray-800 bg-gray-200 hover:bg-gray-100 p-3 m-2 rounded cursor-move"
        style={{ opacity }} >
        <h1>{element && element[descriptor]}</h1>
      </div>
    </div>
  );
}
