import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router";

import { DeleteButton, StandardButton } from "./components/Buttons";

import { errorToString } from "./js/helpers";


const Admin = function () {
  const history = useHistory();
  let [initialLoad, setInitialLoad] = useState(true);
  let [loading, setLoading] = useState(true);
  let [info, setInfo] = useState<JSX.Element | null>(null);
  let [error, setError] = useState<OptionalString>(null);

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(true);
      axios.get("/users/me/permissions/ADMIN")
        .then(() => setLoading(false))
        .catch(() => history.push("/"));
    }
  }, [history, initialLoad])

  const createRegistrationCode = () => {
    setInfo(null);
    setError(null);
    axios.post("/registration/codes")
      .then((res) => setInfo(
        <p><b>Registration Code:</b> <code>{res.data?.code}</code></p>
      ))
      .catch((err) => setError(`Registration Code creation error: ${errorToString(err)}`));
  }

  const deleteRegistrationCode = (id: number) => {
    setInfo(null);
    setError(null);
    axios.delete(`/registration/codes/${id}`)
      .then(() => {
        setInfo(<p>Deleted registration code {id}</p>);
        setTimeout(showRegistrationCodes, 3000);
      })
      .catch((err) => setError(`Registration Code deletion error: ${errorToString(err)}`));
  }

  const showRegistrationCodes = () => {
    interface code {
      id: number,
      created: string,
      used: boolean,
    }

    axios.get("/registration/codes")
      .then((res) => setInfo(
        <div>
          <p>Registration Codes:</p>
          <table className="w-full">
            <thead>
              <tr className="italic">
                <td>Created</td>
                <td>Used</td>
                <td>Delete?</td>
              </tr>
            </thead>
            <tbody>
              {res.data.map((code: code, idx: number) => (
                <tr
                  key={idx}
                  className="border-t-2 hover:bg-green-100">
                  <td>{new Date(`${code.created}Z`).toLocaleString()}</td>
                  <td>{code.used ? "✓" : "⨉"}</td>
                  <td><DeleteButton onClick={() => deleteRegistrationCode(code.id)} /></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))
      .catch((err) => setError(errorToString(err)));
  }

  return (
    <div>
      <Helmet>
        <title>Admin | Proto Tracker</title>
        <meta name="description" content="Admin management page for Proto Tracker" />
      </Helmet>
      {
        loading ? "🤔" : (
          <div>
            <h1 className="text-2xl">Admin Dashboard</h1>
            <div className="flex">
              <StandardButton className="flex-1 my-2 mx-1" onClick={() => createRegistrationCode()}>
                Create Registration Code
              </StandardButton>
              <StandardButton className="flex-1 my-2 mx-1" onClick={() => showRegistrationCodes()}>
                View Registration Codes
              </StandardButton>
            </div>
            {error && <p className="p-2 border bg-yellow-200 border-yellow-300 rounded">{error}</p>}
            {info}
          </div>
        )
      }</div>
  );
}

export default Admin;
