import axios from "axios";
import { useState } from "react"
import { Helmet } from "react-helmet-async";
import { useHistory, useParams } from "react-router";
import { LinkButton, StandardButton } from "./components/Buttons";
import { PasswordInput } from "./components/Inputs"
import { errorToString, validatePassword } from "./js/helpers";

interface ResetParams { code: string }
const ResetPassword = function () {
  const history = useHistory();
  let [password, setPassword] = useState("");
  let [confirmPassword, setConfirmPassword] = useState("");
  let [error, setError] = useState<OptionalString>(null);
  let [success, setSuccess] = useState(false);
  let { code } = useParams<ResetParams>();

  const doReset = function () {
    if (password !== confirmPassword) {
      setError("Confirmation password does not match initial password");
      return;
    }
    const validation = validatePassword(password);
    if (validation !== null) {
      setError(validation);
      return;
    }

    const data = { password, code };
    axios
      .put("/users/me/reset_password", data)
      .then(() => {
        setSuccess(true);
        setTimeout(() => history.push("/login"), 3000);
      })
      .catch((err) => {
        const errDetail = errorToString(err);
        console.log(errDetail);
        setError(`Login error: ${errDetail}`);
      });
  }

  return (
    <div>
      <Helmet>
        <title>Reset Password | Proto Tracker</title>
        <meta name="description" content="Password reset page for Proto Tracker." />
      </Helmet>
      <h1 className="text-2xl mb-4">Password Reset</h1>
      <form className="flex flex-col gap-2">
        {error && <p className="p-2 border bg-yellow-200 border-yellow-300 rounded">{error}</p>}
        {success && <p className="p-2 border bg-green-200 border-green-300 rounded">Password reset success! Redirecting to login page...</p>}
        <PasswordInput value={password} onChange={(value: string) => setPassword(value)} autoComplete="new-password">Password:</PasswordInput>
        <PasswordInput value={confirmPassword} onChange={(value: string) => setConfirmPassword(value)} autoComplete="new-password">Confirm Password:</PasswordInput>
        <StandardButton onClick={doReset} type="button">Reset Password</StandardButton>
        <p>Know your password and just need to login? <LinkButton to="/login">Go to Login</LinkButton></p>
      </form>
    </div>
  );
}

export default ResetPassword;
