import axios from "axios";
import html2canvas from "html2canvas";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import QRCode from "react-qr-code";
import { useHistory, useParams } from "react-router";

import { SlimButton, StandardButton, WarningButton } from "./components/Buttons";
import { DateInput, SelectOrCreateInput, TextAreaInput, TextInput } from "./components/Inputs";
import { BasicModal, FormModal } from "./components/Modals";
import { StandardSpinner } from "./components/Spinners";
import { BodyCell, HeaderCell, TableRow } from "./components/Tables";

import { errorToString, getObjById, sortByElement, valuePop } from "./js/helpers";

const Blank = () => <p className="italic text-gray-600">None</p>;

interface PrototypeDialogProps extends DialogProps {
  proto: Prototype,
}
const ModifyModal = function ({ onClose, onSuccess, proto }: PrototypeDialogProps) {
  const history = useHistory();
  let [isOpen, setIsOpen] = useState(true);
  let [startedLoading, setStartedLoading] = useState(false);
  let [error, setError] = useState<OptionalString>(null);
  let [modifying, setModifying] = useState(false);
  let [updating, setUpdating] = useState(false);
  let [serial, setSerial] = useState(proto.serial);
  let [manufactured, setManufactured] = useState(new Date(proto.manufactured));
  let [notes, setNotes] = useState(proto.notes);

  let [designCreate, setDesignCreate] = useState(false);
  let [designId, setDesignId] = useState<number | null>(proto.design.id);
  let [designValue, setDesignValue] = useState('');
  let [designs, setDesigns] = useState<Array<Design>>([proto.design]);
  let [revisionCreate, setRevisionCreate] = useState(false);
  let [revisionId, setRevisionId] = useState<number | null>(proto.revision.id);
  let [revisionValue, setRevisionValue] = useState('');
  let [revisions, setRevisions] = useState<Array<Revision>>([proto.revision]);
  let [locationCreate, setLocationCreate] = useState(false);
  let [locationId, setLocationId] = useState<number | null>(proto.location?.id || null);
  let [locationValue, setLocationValue] = useState('');
  let [locations, setLocations] = useState<Array<Location>>(proto.location ? [proto.location] : []);
  let [statusCreate, setStatusCreate] = useState(false);
  let [statusId, setStatusId] = useState<number | null>(proto.status?.id || null);
  let [statusValue, setStatusValue] = useState('');
  let [statuses, setStatuses] = useState<Array<Status>>(proto.status ? [proto.status] : []);

  useEffect(() => {
    if (!startedLoading) {
      setStartedLoading(true);
      axios.get("/designs/")
        .then((res) => setDesigns(res.data))
        .catch((err) => setError(errorToString(err)));
      axios.get("/locations/")
        .then((res) => setLocations(res.data))
        .catch((err) => setError(errorToString(err)));
      axios.get("/statuses/")
        .then((res) => setStatuses(res.data))
        .catch((err) => setError(errorToString(err)));
    }
  }, [startedLoading]);

  useEffect(() => {
    if (designId && !designCreate) {
      axios.get(`/revisions/?design_id=${designId}`)
        .then((res) => {
          setRevisions(res.data);
          if (getObjById(res.data, revisionId) === null) {
            setRevisionId(res.data.length > 0 ? res.data[0].id : null);
          }
        })
        .catch((err) => setError(errorToString(err)));
    } else {
      setRevisions([]);
      setRevisionId(null);
      setRevisionCreate(true);
    }
  }, [designId, designCreate, revisionId]);

  const finish = useCallback((success = false) => {
    if (success) {
      if (designId !== proto.design.id || serial !== proto.serial) {
        history.replace("/");
        history.replace(`/design/${designId}/proto/${serial}`);
      } else if (onSuccess) {
        onSuccess();
      }
    }
    setIsOpen(false);
    onClose();
  }, [designId, history, onClose, onSuccess, proto, serial]);

  const validateForm = function () {
    let err = null;

    if (!serial) err = "Serial must be set";
    else if (!manufactured) err = "Must set date of manufacture";
    else if (designCreate && !designValue) err = "Must set a name for the Design to be created";
    else if (designCreate && designs.map((design) => design.name).includes(designValue)) err = "Design name already exists";
    else if (!designCreate && !designId) err = "Must select an existing design to use if not creating one";
    else if (revisionCreate && !revisionValue) err = "Must set a name for the Revision to be created";
    else if (revisionCreate && revisions.map((revision) => revision.name).includes(revisionValue)) err = "Revision name already exists";
    else if (!revisionCreate && !revisionId) err = "Must select an existing revision to use if not creating one";
    else if (locationCreate && !locationValue) err = "Must set a name for the Location to be created";
    else if (locationCreate && locations.map((location) => location.name).includes(locationValue)) err = "Location name already exists";
    else if (!locationCreate && !locationId) err = "Must select an existing location to use if not creating one";
    else if (statusCreate && !statusValue) err = "Must set a name for the Status to be created";
    else if (statusCreate && statuses.map((status) => status.name).includes(statusValue)) err = "Status name already exists";
    else if (!statusCreate && !statusId) err = "Must select an existing status to use if not creating one";

    return err; // Validation successful
  }

  const prepare = function () {
    setError(null);
    const err = validateForm();
    if (err) {
      setError(err);
      return;
    }

    if (designCreate) createDesign();
    if (!designCreate && revisionCreate) createRevision(designId);
    if (locationCreate) createLocation();
    if (statusCreate) createStatus();
    setUpdating(true);
    setModifying(true);
  }

  const createComponent = function (
    url: string,
    name: string,
    components: Array<object>,
    setComponents: Function,
    idSetCb: Function,
    createSetCb: Function,
    finalCb?: Function,
  ) {
    axios.post(url, { name })
      .then((res) => {
        const component = res.data;
        setComponents(components.concat(component));
        idSetCb(component.id);
        createSetCb(false);
        if (finalCb) {
          finalCb(component);
        }
      })
      .catch((err) => {
        setUpdating(false);
        setModifying(false);
        setError(errorToString(err));
      });
  }

  const createDesign = function () {
    createComponent("/designs/", designValue, designs, setDesigns, setDesignId, setDesignCreate, (comp: Design) => createRevision(comp.id));
  }

  const createRevision = function (id: number | null) {
    if (id === null) {
      console.error("Attempting to create revision for design with id = null");
      return;
    }

    createComponent(
      `/revisions/?design_id=${id}`, revisionValue, revisions, setRevisions, setRevisionId, setRevisionCreate
    );
  }

  const createLocation = function () {
    createComponent("/locations/", locationValue, locations, setLocations, setLocationId, setLocationCreate);
  }

  const createStatus = function () {
    createComponent("/statuses/", statusValue, statuses, setStatuses, setStatusId, setStatusCreate);
  }

  const modify = useCallback(() => {
    if (designCreate || revisionCreate || locationCreate || statusCreate) {
      return;  // Not yet finished creating components
    }
    setModifying(false);

    const protoData = {
      id: proto.id,
      serial,
      notes,
      manufactured: manufactured.toISOString().split("T")[0],
      design_id: designId,
      revision_id: revisionId,
      location_id: locationId,
      status_id: statusId
    };
    axios.put("/prototypes/", protoData)
      .then(() => onSuccess && onSuccess())
      .then(() => finish(true))
      .catch((err) => {
        setError(`Submission error: ${errorToString(err)}`);
        setUpdating(false);
      });
  }, [designCreate, designId, finish, locationCreate, locationId, manufactured, notes, onSuccess, proto.id, revisionCreate, revisionId, serial, statusCreate, statusId]);

  useEffect(() => {
    if (modifying) {
      modify();
    }
  }, [designCreate, revisionCreate, locationCreate, statusCreate, modifying, modify]);

  return (
    <FormModal
      isOpen={isOpen}
      onClose={finish}
      onOk={prepare}
      okButton="Update"
      title="Update Prototype"
      description="Use the following form to update the details of a prototype."
      error={error}
      loading={updating} >
      <TextInput value={serial} onChange={setSerial} maxLength={100}>
        Serial:
      </TextInput>
      <DateInput selected={manufactured} onChange={setManufactured}>
        Date Manufactured:
      </DateInput>
      <TextAreaInput value={notes || ""} onChange={setNotes} rows={2} maxLength={1000}>
        Notes:
      </TextAreaInput>
      <SelectOrCreateInput
        name="design"
        setCreate={setDesignCreate}
        setId={setDesignId}
        setValue={setDesignValue}
        selectOptions={designs}
        selected={getObjById(designs, designId)}
        create={designCreate}
        maxLength={100} >
        Design:
      </SelectOrCreateInput>
      <SelectOrCreateInput
        name="revision"
        setCreate={setRevisionCreate}
        setId={setRevisionId}
        setValue={setRevisionValue}
        selectOptions={revisions}
        selected={getObjById(revisions, revisionId)}
        create={revisionCreate}
        maxLength={20} >
        Revision:
      </SelectOrCreateInput>
      <SelectOrCreateInput
        name="location"
        setCreate={setLocationCreate}
        setId={setLocationId}
        setValue={setLocationValue}
        selectOptions={locations}
        selected={getObjById(locations, locationId)}
        create={locationCreate}
        maxLength={100} >
        Location:
      </SelectOrCreateInput>
      <SelectOrCreateInput
        name="status"
        setCreate={setStatusCreate}
        setId={setStatusId}
        setValue={setStatusValue}
        selectOptions={statuses}
        selected={getObjById(statuses, statusId)}
        create={statusCreate}
        maxLength={100} >
        Status:
      </SelectOrCreateInput>
    </FormModal>
  );
}

const DeleteModal = function ({ onClose, onSuccess, proto }: PrototypeDialogProps) {
  let [isOpen, setIsOpen] = useState(true);
  let [deleting, setDeleting] = useState(false);
  let [error, setError] = useState<OptionalString>(null);
  let [verification, setVerification] = useState('');
  const confirmation = "CONFIRM";
  const deleteText = "Delete";

  const close = (success = false) => {
    if (success) {
      if (onSuccess) {
        onSuccess();
      }
    }
    setIsOpen(false);
    onClose();
  }

  const triggerDelete = () => {
    if (confirmation !== verification) {
      setError(`Type ${confirmation} in the text box before clicking ${deleteText} to proceed`);
      return;
    }

    setDeleting(true);
    axios.delete(`/prototypes/${proto.id}`)
      .then(() => close(true))
      .catch((err) => {
        setError(`Deletion error: ${errorToString(err)}`);
        setDeleting(false);
      });
  }

  return (
    <FormModal
      isOpen={isOpen}
      onClose={close}
      onOk={triggerDelete}
      okButton={deleteText}
      title="Delete Prototype"
      description={`This will permanently delete prototype with serial '${proto.serial}' from your account, along with any information associated with it. If you are sure this is what you want to do, type ${confirmation} in the box below and click ${deleteText}.`}
      error={error}
      loading={deleting} >
      <TextInput value={verification} onChange={setVerification}>
        {`Type ${confirmation} to confirm deletion:`}
      </TextInput>
    </FormModal>
  );
}

const NotesModal = function ({ onClose, proto }: PrototypeDialogProps) {
  const [isOpen, setIsOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<OptionalString>(null);
  const [notes, setNotes] = useState<Array<Note> | null>(null);

  useEffect(() => {
    axios.get(`/prototypes/notes/${proto.id}`)
      .then((res) => setNotes(res.data))
      .catch((err) => setError(errorToString(err)))
      .finally(() => setLoading(false));
  }, [proto.id]);

  const close = () => {
    setIsOpen(false);
    onClose();
  }

  return (
    <BasicModal
      isOpen={isOpen}
      onClose={close}
      title={`Prototype ${proto.serial} Notes History`}
      error={error} >
      {loading ? <StandardSpinner /> : <span />}
      {notes
        ? (
          <div>
            <table className="w-full">
              <thead>
                <tr className="italic">
                  <td>Notes</td>
                  <td>Changed At</td>
                </tr>
              </thead>
              <tbody>
                {notes.map((note, idx: number) => (
                  <tr
                    key={idx}
                    className="border-t-2 hover:bg-green-100">
                    <td className="break-all">{note.note}</td>
                    <td>{new Date(note.created).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )
        : <span />}
    </BasicModal>
  );
}

const LocationModal = function ({ onClose, proto }: PrototypeDialogProps) {
  const [isOpen, setIsOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<OptionalString>(null);
  const [locations, setLocations] = useState<Array<LocationChange> | null>(null);

  useEffect(() => {
    axios.get(`/prototypes/locations/${proto.id}`)
      .then((res) => setLocations(res.data))
      .catch((err) => setError(errorToString(err)))
      .finally(() => setLoading(false));
  }, [proto.id]);

  const close = () => {
    setIsOpen(false);
    onClose();
  }

  return (
    <BasicModal
      isOpen={isOpen}
      onClose={close}
      title={`Prototype ${proto.serial} Location History`}
      error={error} >
      {loading ? <StandardSpinner /> : <span />}
      {locations
        ? (
          <div>
            <table className="w-full">
              <thead>
                <tr className="italic">
                  <td>Location</td>
                  <td>Changed At</td>
                </tr>
              </thead>
              <tbody>
                {locations.map((loc, idx: number) => (
                  <tr
                    key={idx}
                    className="border-t-2 hover:bg-green-100">
                    <td className="break-all">{loc.location.name}</td>
                    <td>{new Date(loc.created).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )
        : <span />}
    </BasicModal>
  );
}

const StatusModal = function ({ onClose, proto }: PrototypeDialogProps) {
  const [isOpen, setIsOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<OptionalString>(null);
  const [statuses, setStatuses] = useState<Array<StatusChange> | null>(null);

  useEffect(() => {
    axios.get(`/prototypes/statuses/${proto.id}`)
      .then((res) => setStatuses(res.data))
      .catch((err) => setError(errorToString(err)))
      .finally(() => setLoading(false));
  }, [proto.id]);

  const close = () => {
    setIsOpen(false);
    onClose();
  }

  return (
    <BasicModal
      isOpen={isOpen}
      onClose={close}
      title={`Prototype ${proto.serial} Location History`}
      error={error} >
      {loading ? <StandardSpinner /> : <span />}
      {statuses
        ? (
          <div>
            <table className="w-full">
              <thead>
                <tr className="italic">
                  <td>Location</td>
                  <td>Changed At</td>
                </tr>
              </thead>
              <tbody>
                {statuses.map((stat, idx: number) => (
                  <tr
                    key={idx}
                    className="border-t-2 hover:bg-green-100">
                    <td className="break-all">{stat.status.name}</td>
                    <td>{new Date(stat.created).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )
        : <span />}
    </BasicModal>
  );
}

interface PrototypeParams {
  designId: string,
  serial: string,
}
const Prototype = function () {
  let [initialLoad, setInitialLoad] = useState(true);
  let [prototype, setPrototype] = useState<Prototype | null>(null);
  let [error, setError] = useState<OptionalString>(null);
  let [showModifyModal, setShowModifyModal] = useState(false);
  let [showDeleteModal, setShowDeleteModal] = useState(false);
  let [showNotesHistoryModal, setShowNotesHistoryModal] = useState(false);
  let [showLocationHistoryModal, setShowLocationHistoryModal] = useState(false);
  let [showStatusHistoryModal, setShowStatusHistoryModal] = useState(false);
  let [appliedIds, setAppliedIds] = useState<Array<number>>([]);
  let [generatingQr, setGeneratingQr] = useState(false);
  let { designId, serial } = useParams<PrototypeParams>();
  let history = useHistory();

  const getPrototype = useCallback(() => {
    if (designId !== null && serial !== null) {
      axios.get(`/prototypes/single?design_id=${designId}&serial=${serial}`)
        .then((res) => {
          setPrototype(res.data);
          setAppliedIds(res.data.mods.map((mod: Mod) => (mod.id)));
        })
        .catch((err) => setError(errorToString(err)));
    }
  }, [designId, serial]);

  const toggleMod = (modId: number, applied: boolean) => {
    setError(null);
    if (prototype === null) {
      setError("Attempting to toggle mods for null prototype");
      return;
    }

    if (applied) {
      if (appliedIds.includes(modId)) {
        setError("Mod already applied");
        return;
      }

      let newAppliedIds = appliedIds.slice();
      newAppliedIds.push(modId);
      setAppliedIds(newAppliedIds);

      axios.put(`/mods/${modId}/apply/${prototype.id}`)
        .catch((err) => setError(`Error setting mod: ${errorToString(err)}`));
    } else {
      if (!appliedIds.includes(modId)) {
        setError("Mod not applied");
        return;
      }

      setAppliedIds(valuePop(appliedIds, modId));

      axios.put(`/mods/${modId}/remove/${prototype.id}`)
        .catch((err) => setError(`Error removing mod: ${errorToString(err)}`));
    }
  };

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
      getPrototype();
    }
  }, [initialLoad, getPrototype]);

  const saveQr = () => {
    setGeneratingQr(true);
    // @ts-ignore
    html2canvas(document.querySelector("#qr"))
      .then(canvas => {
        let imgData = canvas.toDataURL("image/png");
        imgData = imgData.replace("image/png", "application/octet-stream");

        const link = document.createElement("a");
        link.download = `Prototype ${prototype?.serial} QR code.png`;
        link.href = imgData;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setGeneratingQr(false);
      });
  }

  const dateStringify = (d: string) => new Date(d + "Z").toLocaleDateString();

  const sortedMods = prototype ? sortByElement(prototype.revision.mods, "name") : [];

  return (
    <div>
      <Helmet>
        <title>{prototype?.serial || "Prototype"} | Proto Tracker</title>
        <meta name="description" content="Prototype details." />
      </Helmet>
      <div className="flex">
        <h1 className="text-2xl">Prototype Details</h1>
        <div className="flex-1" />
        <StandardButton className="mt-0" onClick={() => history.goBack()}>
          Back to Dashboard
        </StandardButton>
      </div>
      {error && <p className="p-2 border bg-yellow-200 border-yellow-300 rounded">{error}</p>}
      <div className="mt-2 flex flex-col md:flex-row gap-2">
        <table className="flex-grow">
          <tbody>
            <TableRow>
              <HeaderCell>Serial</HeaderCell>
              <BodyCell>{prototype && prototype.serial}</BodyCell>
            </TableRow>
            <TableRow>
              <HeaderCell>Manufactured</HeaderCell>
              <BodyCell>{prototype && dateStringify(prototype.manufactured)}</BodyCell>
            </TableRow>
            <TableRow>
              <HeaderCell>Notes</HeaderCell>
              <BodyCell>{prototype && (prototype.notes || <Blank />)}</BodyCell>
              <BodyCell>{prototype && prototype.notes_changed && "Updated " + dateStringify(prototype.notes_changed)}</BodyCell>
              <BodyCell><SlimButton onClick={() => setShowNotesHistoryModal(true)}>History</SlimButton></BodyCell>
            </TableRow>
            <TableRow>
              <HeaderCell>Design</HeaderCell>
              <BodyCell>{prototype && prototype.design?.name}</BodyCell>
            </TableRow>
            <TableRow>
              <HeaderCell>Description</HeaderCell>
              <BodyCell className="break-all" colSpan={2}>{prototype && (prototype.design?.description || <Blank />)}</BodyCell>
            </TableRow>
            <TableRow>
              <HeaderCell>Revision</HeaderCell>
              <BodyCell>{prototype && prototype.revision?.name}</BodyCell>
            </TableRow>
            <TableRow>
              <HeaderCell>Revision Notes</HeaderCell>
              <BodyCell>{prototype && (prototype.revision?.notes || <Blank />)}</BodyCell>
            </TableRow>
            <TableRow>
              <HeaderCell>Location</HeaderCell>
              <BodyCell>{prototype && prototype.location && prototype.location.name}</BodyCell>
              <BodyCell>{prototype && prototype.location_changed && "Updated " + dateStringify(prototype.location_changed)}</BodyCell>
              <BodyCell><SlimButton onClick={() => setShowLocationHistoryModal(true)}>History</SlimButton></BodyCell>
            </TableRow>
            <TableRow>
              <HeaderCell>In-house</HeaderCell>
              <BodyCell>{prototype && (prototype.location?.in_house ? "✓" : "⨉")}</BodyCell>
            </TableRow>
            <TableRow>
              <HeaderCell>Location Notes</HeaderCell>
              <BodyCell>{prototype && (prototype.location?.notes || <Blank />)}</BodyCell>
            </TableRow>
            <TableRow>
              <HeaderCell>Status</HeaderCell>
              <BodyCell>{prototype && prototype.status && prototype.status.name}</BodyCell>
              <BodyCell>{prototype && prototype.status_changed && "Updated " + dateStringify(prototype.status_changed)}</BodyCell>
              <BodyCell><SlimButton onClick={() => setShowStatusHistoryModal(true)}>History</SlimButton></BodyCell>
            </TableRow>
            <TableRow>
              <HeaderCell>Status Notes</HeaderCell>
              <BodyCell>{prototype && (prototype.status?.notes || <Blank />)}</BodyCell>
            </TableRow>
          </tbody>
        </table>
        <div className="flex flex-col">
          <p className="mx-auto">This QR code links back to this page.</p>
          <div id="qr" className="mx-auto text-center p-2">
            <QRCode value={window.location.href} />
            <h2 className="font-semibold text-2xl pb-1">{prototype?.serial}</h2>
          </div>
          <StandardButton loading={generatingQr} onClick={saveQr} >Download QR code</StandardButton>
        </div>
      </div>
      <div>
        <h1 className="mt-4 mb-2 text-2xl">Mods</h1>
        {(prototype && prototype.revision.mods.length > 0) ? (
          <div>
            <table className="w-full">
              <thead>
                <tr className="italic">
                  <td>Name</td>
                  <td>Description</td>
                  <td>Applied</td>
                </tr>
              </thead>
              <tbody>
                {sortedMods.map((mod, idx: number) => (
                  <tr
                    key={idx}
                    className="border-t-2 hover:bg-green-100">
                    <td className="whitespace-nowrap pr-4 font-semibold">{mod.name}</td>
                    <td className="break-all">{mod.description || <p className="italic">Not set</p>}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={appliedIds?.includes(mod.id)}
                        onChange={(event) => toggleMod(mod.id, event.target.checked)}
                        style={{ width: 32, height: 32 }}
                        className="ml-2 rounded" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : <p>No mods necessary for this revision of this design! Head back to the dashboard if you would like to change this.</p>}

        {showModifyModal && prototype && <ModifyModal proto={prototype} onClose={() => setShowModifyModal(false)} onSuccess={getPrototype} />}
        {showDeleteModal && prototype && <DeleteModal proto={prototype} onClose={() => setShowDeleteModal(false)} onSuccess={() => history.goBack()} />}
        {showNotesHistoryModal && prototype && <NotesModal proto={prototype} onClose={() => setShowNotesHistoryModal(false)} />}
        {showLocationHistoryModal && prototype && <LocationModal proto={prototype} onClose={() => setShowLocationHistoryModal(false)} />}
        {showStatusHistoryModal && prototype && <StatusModal proto={prototype} onClose={() => setShowStatusHistoryModal(false)} />}
        <div className="flex gap-2">
          <StandardButton className="flex-grow mt-2 font-semibold" onClick={() => setShowModifyModal(true)}>
            Modify Prototype
          </StandardButton>
          <WarningButton className="mt-2 font-semibold" onClick={() => setShowDeleteModal(true)}>
            Delete Prototype
          </WarningButton>
        </div>
      </div>
    </div>
  );
}

export default Prototype;
