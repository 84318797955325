interface BaseTableProps {
  className?: string,
}

interface RowProps extends BaseTableProps {
  children: JSX.Element | Array<JSX.Element>,
}

interface CellProps extends React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>, BaseTableProps {
  children: JSX.Element | string | null,
}

export const TableRow = (props: RowProps) => {
  return <tr {...props} className={`border-t-2 hover:bg-green-100 ${props.className}`}>{props.children}</tr>;
}

export const HeaderCell = (props: CellProps) => {
  return <td {...props} className={`font-semibold ${props.className}`}>{props.children}</td>;
}

export const BodyCell = (props: CellProps) => {
  return <td {...props} className={props.className}>{props.children}</td>;
}
