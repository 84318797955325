import { Helmet } from "react-helmet-async";

import { PlainLink } from './components/Links';
import HeroImage from './images/pcb-2655767.jpg';
import PCBImage from './images/processor-921456.jpg';

interface ClassNameProp { className?: string, [x: string]: any }
interface StyleProp { style?: object, [x: string]: any }

const PlainPara = (props: ClassNameProp) => {
  return <p {...props} className={`sm:leading-relaxed md:leading-loose text-center ${props.className}`} />;
}

const EmojiPara = (props: ClassNameProp) => {
  return <PlainPara {...props} className={`text-5xl sm:text-6xl md:text-7xl lg:text-8xl`} />;
}

const Para = (props: ClassNameProp) => {
  return <PlainPara {...props} className={`text-2xl sm:text-3xl md:text-4xl lg:text-5xl ${props.className}`} />;
}

const SmallPara = (props: ClassNameProp) => {
  return <PlainPara {...props} className={`text-lg md:text-xl lg:text-2xl ${props.className}`} />;
}

const Code = (props: ClassNameProp) => {
  return <code {...props} className={`p-4 bg-gray-200 border border-gray-500 text-gray-800 rounded ${props.className}`} />;
}

const Section = (props: StyleProp) => {
  return <div {...props} style={{ ...props.style, height: "70vh" }} />
}

const PlainGridSection = (props: ClassNameProp) => {
  return <Section {...props} className={`grid grid-cols-1 md:grid-cols-2 text-center ${props.className}`} />;
}

const GridSection = (props: ClassNameProp) => {
  return <PlainGridSection {...props} className={`content-evenly px-4 sm:px-6 md:px-10 ${props.className}`} />;
}

interface HomeProps {
  user: object | null
}
const Home = function ({ user }: HomeProps) {
  return (
    <div>
      <Helmet>
        <title>Proto Tracker</title>
        <meta name="description" content="A webapp for tracking prototype status and location." />
      </Helmet>
      <Section
        style={{
          backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), url(${HeroImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"
        }}>
        <div className="text-center flex flex-col justify-center h-full w-full backdrop-filter backdrop-blur-sm text-gray-800">
          <h1 className="lg:text-6xl md:text-5xl text-4xl mb-6">Welcome to</h1>
          <span className="lg:text-8xl md:text-7xl text-6xl font-logo">Proto Tracker</span>
        </div>
      </Section>
      <GridSection className="bg-gray-50 text-gray-800">
        <Para>Track all your prototype information in a single, easily accessible place</Para>
        <EmojiPara>🔎📋</EmojiPara>
        <Para>Web-based system means it can be used by all users, all the time - no more share drive spreadsheets</Para>
        <EmojiPara>👩‍💼👨‍💼🌐👩‍💼👨‍💼</EmojiPara>
      </GridSection>
      <GridSection className="text-gray-200">
        <EmojiPara>✔❔❌</EmojiPara>
        <Para>Easily check and update the status of your devices</Para>
        <EmojiPara>🗺🧭</EmojiPara>
        <Para>Know where all your devices are at a glance</Para>
      </GridSection>
      <div className="bg-green-800">
        <p className="p-12 pb-16 text-6xl text-gray-200 italic text-center">Never lose a prototype again</p>
      </div>
      <PlainGridSection>
        <div
          className="filter brightness-75 blur-sm"
          style={{
            backgroundImage: `url(${PCBImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center"
          }} />
        <div className="bg-gray-50 flex flex-col justify-evenly px-4">
          <SmallPara className="text-gray-800">
            Proto Tracker is currently in closed alpha.
            If you would like to be a part of the pre-release testing stage of Proto Tracker,
            please contact me via email at:
          </SmallPara>
          <SmallPara>
            <Code className="break-none">contact at proto-tracker.com</Code>
          </SmallPara>
          <SmallPara>{
            user ?
              <>Head to the <PlainLink className="text-gray-600 hover:text-gray-400" to="/dashboard">Dashboard</PlainLink> page to see your prototypes!</> :
              <>If you already have an account or have a registration code and wish to sign up, head to the <PlainLink className="text-gray-600 hover:text-gray-400" to="/login">Login</PlainLink> page to get started!</>
          }</SmallPara>
        </div>
      </PlainGridSection>
    </div>
  );
}

export default Home;
