import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router';

import { DropdownInfo, DropdownLink, NavbarDropdown } from './components/Dropdowns';
import { FooterLink, NavbarLink } from './components/Links';
import { PrivacyPolicy, TermsOfService, CookiePolicy, AcceptableUsePolicy } from './components/Terms';

import Admin from './Admin';
import Dashboard from './Dashboard';
import Home from './Home';
import Login from './Login';
import Logout from './Logout';
import Prototype from './Prototype';
import ResetPassword from './ResetPassword';
import { Card } from './components/Card';
import { errorToString } from './js/helpers';
import smallTranspranentLogo from './images/logo_transparent_sm.png';
import VerifyEmail from './VerifyEmail';
import Account from './Account';
import { StandardSpinner } from './components/Spinners';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND || "http://localhost:8000";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App = function () {
  const history = useHistory();
  let [user, setUser] = useState<null | User>(null);
  let [loadingUser, setLoadingUser] = useState(true);
  let [token, setToken] = useState(window.localStorage.getItem("token"));

  const getUserDetails = useCallback(() => {
    axios
      .get("/users/me")
      .then((res) => {
        const userData = res.data || {};
        setUser(userData);
        if (loadingUser) setLoadingUser(false);
      })
      .catch((err) => {
        const errDetail = errorToString(err);
        console.log(errDetail);
        setToken(null);
        history.push("/login");
      });
  }, [history, loadingUser]);

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = token ? `Bearer ${token}` : null;
    window.localStorage.setItem("token", token || "");

    if (token) {
      getUserDetails();
    } else {
      setUser(null);
      setLoadingUser(false);
    }
  }, [history, token, getUserDetails]);

  interface RequireLoginProps {
    children: JSX.Element,
  }
  const RequireLogin = ({ children }: RequireLoginProps) => {
    if (user === null) return <Redirect to="/login" />;
    return children;
  };

  return (
    <div
      className="pt-0 min-h-screen flex flex-col"
      style={{
        background: "rgb(20,23,24) linear-gradient(127deg, rgba(20,23,24,1) 0%, rgba(55,62,65,1) 100%)"
      }}>
      <ScrollToTop />
      <div className="sticky z-10 top-0 left-0 w-full bg-green-700 text-gray-100">
        <div className="flex max-w-screen-lg ml-auto mr-auto px-3 py-1">
          <NavbarLink className="flex no-underline" nopad={true} to="/">
            <img
              src={smallTranspranentLogo}
              alt="Proto Tracker logo"
              width="36"
              height="36"
              className="m-0 mr-2"
              style={{ maxWidth: 36, maxHeight: 36 }} />
            <p className="sm:block hidden text-2xl font-logo m-0 mt-1">Proto Tracker</p>
          </NavbarLink>
          <h1 className="text-xl pl-4 pt-1 md:block hidden">Never lose a prototype again</h1>
          <div className="flex-grow" />
          {user ? (<>
            <NavbarLink to="/dashboard">Dashboard</NavbarLink>
            <NavbarDropdown header="Account">
              <DropdownInfo>{user.email}</DropdownInfo>
              <DropdownLink to="/manage_account">Manage</DropdownLink>
              <DropdownLink to="/logout">Logout?</DropdownLink>
            </NavbarDropdown>
          </>) : <NavbarLink to="/login">Login</NavbarLink>}
        </div>
      </div>
      <div className="flex-grow">
        {loadingUser ? <StandardSpinner /> : (
          <Switch>
            <Route path="/dashboard"><RequireLogin><Card><Dashboard /></Card></RequireLogin></Route>
            <Route path="/design/:designId/proto/:serial"><RequireLogin><Card><Prototype /></Card></RequireLogin></Route>
            <Route path="/login"><Card><Login loggedIn={Boolean(user)} onLogin={setToken} /></Card></Route>
            <Route path="/logout"><Card><Logout onLogout={() => setToken(null)} /></Card></Route>
            <Route path="/reset_password/:code"><Card><ResetPassword /></Card></Route>
            <Route path="/verify/:code"><Card><VerifyEmail /></Card></Route>
            <Route path="/manage_account"><RequireLogin><Card><Account user={user} onUserChange={getUserDetails} /></Card></RequireLogin></Route>
            <Route path="/admin"><RequireLogin><Card><Admin /></Card></RequireLogin></Route>
            <Route path="/privacy_policy"><Card><PrivacyPolicy /></Card></Route>
            <Route path="/terms_of_service"><Card><TermsOfService /></Card></Route>
            <Route path="/cookie_policy"><Card><CookiePolicy /></Card></Route>
            <Route path="/acceptable_use_policy"><Card><AcceptableUsePolicy /></Card></Route>
            <Route path="/"><Home user={user} /></Route>
          </Switch>
        )}
      </div>
      <div className="w-full flex bg-green-800 text-gray-300">
        <div className="flex max-w-screen-lg flex-grow ml-auto mr-auto px-3 py-6">
          <div className="flex flex-col text-sm gap-1 pr-2">
            <FooterLink to="/privacy_policy">Privacy Policy</FooterLink>
            <FooterLink to="/terms_of_service">Terms of Service</FooterLink>
            <FooterLink to="/acceptable_use_policy">Acceptable Use Policy</FooterLink>
          </div>
          <p className="flex-grow text-right text-2xl">Proto Tracker © Corella Creations 2021</p>
        </div>
      </div>
    </div>
  );
}

export default App;
