import { Link as RouterLink } from "react-router-dom";

interface LinkProps {
  to: string,
  children?: string | JSX.Element | Array<JSX.Element>,
  className?: string,
}

export const Link = function (props: LinkProps) {
  return <PlainLink {...props} className={`text-gray-600 hover:text-green-700 ${props.className}`} />;
}

export const FooterLink = function (props: LinkProps) {
  return <PlainLink {...props} className={`text-gray-300 hover:text-gray-400 ${props.className}`} />;
}

export const PlainLink = function (props: LinkProps) {
  return <RouterLink {...props} className={`underline ${props.className}`} />;
}

interface NavbarLinkProps extends LinkProps {
  nopad?: boolean,
}
export const NavbarLink = function ({ nopad, ...props }: NavbarLinkProps) {
  return <RouterLink {...props} className={`${nopad ? "" : "pt-1"} mx-2 text-xl text-white-300 hover:text-green-300 ${props.className}`} />;
}
