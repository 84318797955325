import axios from "axios";
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async";
import { useHistory, useParams } from "react-router";
import { LinkButton } from "./components/Buttons";
import { errorToString } from "./js/helpers";

interface VerifyParams { code: string }
const VerifyEmail = function () {
  const history = useHistory();
  let [initial, setInitial] = useState(true);
  let [error, setError] = useState<OptionalString>(null);
  let [success, setSuccess] = useState(false);
  let { code } = useParams<VerifyParams>();

  useEffect(() => {
    if (initial) {
      setInitial(false);
      axios
        .put("/users/me/verify", { code })
        .then(() => {
          setSuccess(true);
          setTimeout(() => history.push("/login"), 3000);
        })
        .catch((err) => {
          const errDetail = errorToString(err);
          console.log(errDetail);
          setError(`Login error: ${errDetail}`);
        });
    }
  }, [code, history, initial])

  return (
    <div>
      <Helmet>
        <title>Verify | Proto Tracker</title>
        <meta name="description" content="Account verification page for Proto Tracker." />
      </Helmet>
      <h1 className="text-2xl mb-4">Verify Account</h1>
      {error && <p className="p-2 border bg-yellow-200 border-yellow-300 rounded">{error}</p>}
      {success && <p className="p-2 border bg-green-200 border-green-300 rounded">Account verification successful! Redirecting to login page...</p>}
      <p>Account already activated? <LinkButton to="/login">Go to Login</LinkButton></p>
    </div>
  );
}

export default VerifyEmail;
