export const StandardSpinner = () => (
    <div
        className="mx-auto animate animate-spin-slow border-8 border-t-8 border-gray-300"
        style={{
            width: 100,
            height: 100,
            borderTopColor: "rgb(5, 150, 105)",
            borderRadius: "50%"
        }} />
);

export const SmallSpinner = ({ className }: BasicProps) => (
    <div
        className={`m-auto animate animate-spin-slow border-4 border-t-4 border-gray-300 ${className}`}
        style={{
            width: 24,
            height: 24,
            borderTopColor: "rgb(5, 150, 105)",
            borderRadius: "50%"
        }} />
);
