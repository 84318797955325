import { useState } from "react";
import { useHistory } from "react-router";
import { Link as RouterLink } from "react-router-dom";

export const DropdownInfo = ({ children, className }: BasicProps) => {
  return <div className={`px-4 font-semibold cursor-default ${className}`}>{children}</div>;
};

interface LinkProps extends BasicProps {
  to: string,
}
export const DropdownLink = ({ children, className, to }: LinkProps) => {
  const history = useHistory();
  return (
    <div className="w-full cursor-pointer" onClick={() => history.push(to)}>
      <RouterLink to={to} className={`${className}`}>{children}</RouterLink>
    </div>
  );
};

interface DropdownProps extends BasicProps {
  header: string | JSX.Element,
}
export const NavbarDropdown = ({ children, className, header }: DropdownProps) => {
  let [open, setOpen] = useState(false);
  const childList = Array.isArray(children) ? children : [children];

  return (
    <div
      className="relative pt-1"
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}>
      <div
        className={`cursor-default mx-2 text-xl text-white-300 hover:text-green-300 ${className || ""}`}
        onClick={() => setOpen(true)}>
        {header}
      </div>
      <div
        style={{ backgroundColor: "#171a1b" }}
        className={`flex flex-col text-center py-2 absolute top-full -right-4 border-2 border-gray-50 ${open || "hidden"}`}>
        {childList.map((child, idx) => (
          <div
            key={idx}
            className="hover:bg-gray-600 hover:text-green-200"
            onClick={() => setOpen(false)}>
            {child}
          </div>
        ))}
      </div>
    </div>
  );
};

interface CollapsibleProps extends BasicProps {
  header: string,
  initialState?: boolean,
}
export const Collapsible = ({ children, className, header, initialState }: CollapsibleProps) => {
  const [shown, setShown] = useState(initialState || false);

  return (
    <div className="flex flex-col">
      <div
        className={`flex bg-gray-200 border border-gray-300 p-1 ${shown ? "rounded-t-sm" : "rounded-sm"}`}
        onClick={() => setShown(!shown)}>
        <div className="" style={{ width: 20 }}>{shown ? " ⇓" : "⇒"}</div>
        <span>{header}</span>
        <span className="text-gray-500 text-right flex-grow mr-2">Click to {shown ? "hide" : "show"}</span>
      </div>
      <div className={`${shown ? "visible" : "hidden"} border border-gray-300 rounded-b-sm p-1 ${className || ""}`}>{children}</div>
    </div>
  )
}