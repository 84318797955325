import axios from "axios";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Redirect } from "react-router";
import { StandardButton } from "./components/Buttons";
import { TextInput, PasswordInput, CheckboxInput } from "./components/Inputs";
import { Link } from "./components/Links";
import { FormModal } from "./components/Modals";
import { errorToString, validateEmail, validatePassword } from "./js/helpers";

const RegisterDialog = function ({ onClose, onSuccess }: DialogProps) {
  let [isOpen, setIsOpen] = useState(true);
  let [registering, setRegistering] = useState(false);
  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('');
  let [registrationCode, setRegistrationCode] = useState('');
  let [confirmAge, setConfirmAge] = useState(false);
  let [readToS, setReadToS] = useState(false);
  let [readAcceptableUse, setReadAcceptableUse] = useState(false);
  let [error, setError] = useState<OptionalString>(null);

  const close = function (err?: OptionalString) {
    setIsOpen(false);
    onClose(err);
  }

  const register = function () {
    const passwordValidation = validatePassword(password);
    if (passwordValidation !== null) {
      setError(passwordValidation);
      return;
    }

    if (!confirmAge) {
      setError("You must be at least 16 years old to use this service");
      return;
    }

    if (!readToS) {
      setError("You must read, understand, and agree to the Terms of Service to use this service");
      return;
    }

    if (!readAcceptableUse) {
      setError("You must read, understand, and agree to the Acceptable Use Policy to use this service");
      return;
    }

    setRegistering(true);
    setError(null);
    const user = { email, password, registration_code: registrationCode };
    axios.post("/users/", user)
      .then(() => onSuccess && onSuccess())
      .then(close)
      .catch((err) => {
        setError(`Registration error: ${errorToString(err)}`);
        setRegistering(false);
      });
  }

  return (
    <FormModal
      isOpen={isOpen}
      onClose={close}
      error={error}
      title="Register"
      description="Use the following inputs to register a new Proto Tracker account."
      okButton="Register"
      onOk={register}
      loading={registering} >
      <TextInput value={email} onChange={setEmail} autoComplete="email" maxLength={255}>
        Email:
      </TextInput>
      <PasswordInput value={password} onChange={setPassword} autoComplete="new-password">
        Password:
      </PasswordInput>
      <TextInput value={registrationCode} onChange={setRegistrationCode} maxLength={255}>
        Registration Code:
      </TextInput>
      <CheckboxInput value={confirmAge} onChange={setConfirmAge}>
        I am at least 16 years old:
      </CheckboxInput>
      <CheckboxInput value={readToS} onChange={setReadToS}>
        <>I have read, understood, and agree to the <Link to="/terms_of_service">Terms of Service</Link>:</>
      </CheckboxInput>
      <CheckboxInput value={readAcceptableUse} onChange={setReadAcceptableUse}>
        <>I have read, understood, and agree to the <Link to="/acceptable_use_policy">Acceptable Use Policy</Link>:</>
      </CheckboxInput>
    </FormModal>
  );
}

const ResetDialog = function ({ onClose, onSuccess }: DialogProps) {
  let [isOpen, setIsOpen] = useState(true);
  let [resetting, setResetting] = useState(false);
  let [email, setEmail] = useState('');
  let [error, setError] = useState<OptionalString>(null);

  const close = function (err?: OptionalString) {
    setIsOpen(false);
    onClose(err);
  }

  const reset = function () {
    if (!validateEmail(email)) {
      setError("Invalid email entered - please check and try again.");
      return;
    }

    setResetting(true);
    setError(null);
    axios.post("/users/me/reset_password", { email })
      .then(() => onSuccess && onSuccess())
      .then(close)
      .catch((err) => {
        setError(`Password reset error: ${errorToString(err)}`);
        setResetting(false);
      });
  }

  return (
    <FormModal
      isOpen={isOpen}
      onClose={close}
      error={error}
      title="Reset Password"
      description={(
        <div>
          <p>Use the following form to trigger a password reset.</p>
          <p>A link will be sent to your email which allows you to set a new password.</p>
        </div>
      )}
      okButton="Reset"
      onOk={reset}
      loading={resetting} >
      <TextInput value={email} onChange={setEmail} autoComplete="email" maxLength={255}>
        Email:
      </TextInput>
    </FormModal>
  );
}

interface LoginProps {
  loggedIn: boolean,
  onLogin: Function,
}
const Login = function ({ loggedIn, onLogin }: LoginProps) {
  let [email, setEmail] = useState("");
  let [prevEmail, setPrevEmail] = useState("");
  let [password, setPassword] = useState("");
  let [loggingIn, setLoggingIn] = useState(false);
  let [error, setError] = useState<string | JSX.Element | null>(null);
  let [showRegisterModal, setShowRegisterModal] = useState(false);
  let [showResetModal, setShowResetModal] = useState(false);
  let [registrationSuccess, setRegistrationSuccess] = useState(false);
  let [resetSuccess, setResetSuccess] = useState(false);

  const sendVerification = () => {
    setError(null);
    setRegistrationSuccess(false);
    setResetSuccess(false);
    axios.post("/users/me/resend_verification", { email: prevEmail })
      .then(() => setRegistrationSuccess(true))
      .catch((err) => setError(`Email verification send error: ${errorToString(err)}`));
  };

  const doLogin = (email: string, pass: string) => {
    let loginData = new FormData();
    loginData.append("username", email)
    loginData.append("password", pass)
    setPrevEmail(email);
    setLoggingIn(true);
    axios
      .post("/token", loginData, { headers: { "Content-Type": "multipart/form-data" } })
      .then((res) => onLogin(res.data?.access_token))
      .catch((err) => {
        const errDetail = errorToString(err);
        console.log(errDetail);
        if (errDetail === "Account not verified") {
          setError(<>Account not verified - please follow the link in the verification email sent to verify your account, or click the following button to send a new verification email: <StandardButton onClick={() => sendVerification()}>Send New Verification Email</StandardButton></>);
        } else {
          setError(`Login error: ${errDetail}`);
        }
        setLoggingIn(false);
      });
  };

  const openRegisterModal = () => {
    setRegistrationSuccess(false);
    setShowRegisterModal(true);
    setError(null);
  };

  const closeRegisterModal = (err: OptionalString) => {
    if (err) {
      setError(err);
    }
    setShowRegisterModal(false);
  };

  const openResetModal = () => {
    setResetSuccess(false);
    setShowResetModal(true);
    setError(null);
  };

  const closeResetModal = (err: OptionalString) => {
    if (err) {
      setError(err);
    }
    setShowResetModal(false);
  };

  return (
    <form className="flex flex-col gap-2">
      <Helmet>
        <title>Login | Proto Tracker</title>
        <meta name="description" content="Login page for Proto Tracker." />
      </Helmet>
      {loggedIn && <Redirect to="/dashboard" />}
      {error && <p className="p-2 border bg-yellow-200 border-yellow-300 rounded">{error}</p>}
      {registrationSuccess && <p className="p-2 border bg-green-200 border-green-300 rounded">Verification email sent.<br />Please follow the link in your email to verify your email and get started!</p>}
      {resetSuccess && <p className="p-2 border bg-green-200 border-green-300 rounded">Password reset email sent (if the email entered is registered with Proto Tracker).<br />Please follow the link in your email to reset your password.</p>}
      <TextInput
        value={email}
        onChange={(value: string) => setEmail(value)}
        autoComplete="email"
        maxLength={255}>
        Email:
      </TextInput>
      <PasswordInput
        value={password}
        onChange={(value: string) => setPassword(value)}
        autoComplete="current-password">
        Password:
      </PasswordInput>
      <StandardButton
        loading={loggingIn}
        onClick={() => doLogin(email, password)}
        type="button">
        Login
      </StandardButton>
      {showRegisterModal && <RegisterDialog onClose={(err: OptionalString) => closeRegisterModal(err)} onSuccess={() => setRegistrationSuccess(true)} />}
      {showResetModal && <ResetDialog onClose={(err: OptionalString) => closeResetModal(err)} onSuccess={() => setResetSuccess(true)} />}
      <p>Don't have an account? <StandardButton onClick={openRegisterModal}>Register</StandardButton></p>
      <p>Forgot your password? <StandardButton onClick={openResetModal}>Reset Password</StandardButton></p>
    </form>
  )
}

export default Login;
