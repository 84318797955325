import { Dialog } from '@headlessui/react';
import { useState } from 'react';
import { LightButton, StandardButton } from "./Buttons";

interface BasicButtonProps {
  okButton?: string,
  onClose: Function,
}

interface ModalButtonProps extends BasicButtonProps {
  cancelButton?: string,
  loading?: boolean,
  onOk: Function,
}

interface BaseModalBaseProps {
  isOpen: boolean,
  onClose: Function,
  title: string,
}

interface ModalBaseProps extends BaseModalBaseProps {
  children: JSX.Element | Array<JSX.Element>,
}

interface BasicModalSectionProps extends BasicButtonProps {
  description?: string | JSX.Element,
  error: OptionalString,
  children: JSX.Element | Array<JSX.Element>,
}

interface ModalSectionProps extends BasicModalSectionProps, ModalButtonProps {
  loading: boolean,
}

interface BasicModalProps extends ModalBaseProps, BasicModalSectionProps {
}

interface FormModalProps extends BaseModalBaseProps, ModalSectionProps { }

interface SplitModalSection {
  title: string,
  body: JSX.Element | Array<JSX.Element>,
}

interface SplitModalProps extends BaseModalBaseProps {
  sections: Array<SplitModalSection>,
}

export const SplitModal = ({ isOpen, onClose, sections, title }: SplitModalProps) => {
  let [activeSection, setActiveSection] = useState(0);
  return (
    <Dialog
      open={isOpen}
      onClose={() => onClose()}
      className="fixed inset-0"
      style={{ top: 44 }}>
      <Dialog.Overlay className="fixed bg-black opacity-30 h-full w-full" />
      <div
        className="fixed flex flex-col z-20 bg-white mx-auto inset-0 max-w-screen-sm my-auto p-3 filter drop-shadow-xl overflow-auto"
        style={{ height: "fit-content", maxHeight: "calc(100% - 44px)", top: 44 }}>
        {title && <Dialog.Title className="text-center text-lg font-bold">{title}</Dialog.Title>}
        <div className="flex py-3">
          {sections.map((section, idx) => (
            <div
              key={idx}
              className={`p-3 flex-grow text-center border border-gray-800 ${idx !== activeSection ? "bg-gray-300 hover:bg-gray-200 cursor-pointer" : "font-semibold bg-gray-800 text-white"}`}
              onClick={idx === activeSection ? () => { } : () => setActiveSection(idx)} >
              {section.title}
            </div>
          ))}
        </div>
        {sections[activeSection].body}
      </div>
    </Dialog>
  );
}

export const ModalSection = ({ cancelButton, children, description, error, loading, onClose, okButton, onOk }: ModalSectionProps) => {
  return (
    <div>
      {description && <Dialog.Description className="py-4">{description}</Dialog.Description>}
      {error && <p className="p-2 border bg-yellow-200 border-yellow-300 rounded">{error}</p>}
      {children}
      <ModalButtons
        cancelButton={cancelButton} okButton={okButton} onClose={onClose} onOk={onOk} loading={loading} />
    </div>
  );
}

export const BasicModalSection = ({ children, description, error, onClose, okButton }: BasicModalSectionProps) => {
  return (
    <div>
      {description && <Dialog.Description className="py-4">{description}</Dialog.Description>}
      {error && <p className="p-2 border bg-yellow-200 border-yellow-300 rounded">{error}</p>}
      {children}
      <div className="flex flex-row-reverse mt-2">
        <StandardButton onClick={onClose}>{okButton || "Close"}</StandardButton>
      </div>
    </div>
  );
}

export const FormModalSection = (props: ModalSectionProps) => {
  const { children, ...rest } = props;
  return <ModalSection {...rest}><ModalForm>{children}</ModalForm></ModalSection>
}

const ModalBase = ({ children, isOpen, onClose, title }: ModalBaseProps) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => onClose()}
      className="fixed inset-0"
      style={{ top: 44 }}>
      <Dialog.Overlay className="fixed bg-black opacity-30 h-full w-full" />
      <div
        className="fixed flex flex-col z-10 bg-white mx-auto inset-0 max-w-screen-sm my-auto p-3 filter drop-shadow-xl overflow-auto"
        style={{ height: "fit-content", maxHeight: "calc(100% - 44px)", top: 44 }}>
        {title && <Dialog.Title className="text-center text-lg font-bold">{title}</Dialog.Title>}
        {children}
      </div>
    </Dialog>
  );
}

export const FormModal = ({ cancelButton, children, description, error, isOpen, onClose, okButton, onOk, loading, title }: FormModalProps) => {
  return (
    <ModalBase isOpen={isOpen} onClose={onClose} title={title}>
      <FormModalSection cancelButton={cancelButton} description={description} error={error} onClose={onClose} okButton={okButton} onOk={onOk} loading={loading} >{children}</FormModalSection>
    </ModalBase>
  );
}

export const BasicModal = ({ children, description, error, isOpen, okButton, onClose, title }: BasicModalProps) => {
  return (
    <ModalBase isOpen={isOpen} onClose={onClose} title={title}>
      <BasicModalSection description={description} error={error} okButton={okButton} onClose={onClose} >{children}</BasicModalSection>
    </ModalBase>
  );
}

export const ModalButtons = ({ cancelButton, loading, okButton, onClose, onOk }: ModalButtonProps) => {
  return (
    <div className="flex flex-row-reverse mt-2">
      <StandardButton loading={loading} onClick={onOk}>{okButton || "Proceed"}</StandardButton>
      <LightButton className="mx-2" onClick={onClose}>{cancelButton || "Cancel"}</LightButton>
    </div>
  )
}

interface ModalFormProps {
  className?: string,
  children: JSX.Element | Array<JSX.Element>,
}

export const ModalForm = ({ className, children }: ModalFormProps) => {
  return (
    <form className={`flex flex-col gap-2 mt-2 ${className}`}>
      {children}
    </form>
  );
}
