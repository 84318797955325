import axios from "axios";
import update from "immutability-helper";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router";

import { LightButton, StandardButton } from "./components/Buttons";
import { DragList } from "./components/Card";
import { Collapsible } from "./components/Dropdowns";
import { CheckboxInput, DateInput, NumericInput, SelectInput, SelectOrCreateInput, TextAreaInput, TextInput } from "./components/Inputs"
import { FormModal, FormModalSection, ModalSection, SplitModal } from "./components/Modals";

import { errorToString, getDeepProperty, getObjById, sortByElement } from "./js/helpers";

interface SortToggleProps {
  children: string | JSX.Element,
  id: string,
  onClick: Function,
  sortedBy: SortParams | null,
}
const SortToggle = function ({ children, id, onClick, sortedBy }: SortToggleProps) {
  let toggle = "  ↕  ";
  if (sortedBy && id === sortedBy?.key) {
    toggle = sortedBy.asc ? "⬆" : "⬇";
  }
  return <div onClick={() => onClick(id)}>{children} {toggle}</div>;
};

interface SortParams {
  key: string,
  asc: boolean,
}
const PrototypeList = function ({ prototypes }: PrototypeListProps) {
  let history = useHistory();
  const [sortedProtos, setSortedProtos] = useState<Array<Prototype>>(prototypes);
  const [sortedBy, setSortedBy] = useState<SortParams | null>(null);
  const [filters, setFilters] = useState<ArbitraryObject>({});
  const [serialFilter, setSerialFilter] = useState("");

  useEffect(() => {
    let protos = prototypes.slice();

    if (serialFilter.length > 0) {
      protos = protos.filter((proto) => proto.serial.includes(serialFilter));
    }
    for (const [filter, value] of Object.entries(filters)) {
      const splitFilter = filter.split(".");
      if (!value.includes(" ")) {
        protos = protos.filter((proto) => getDeepProperty(proto, splitFilter) === value);
      }
    }

    if (sortedBy) {
      protos = sortByElement<Prototype>(protos, sortedBy.key);
      if (!sortedBy.asc) {
        protos.reverse();
      }
    }
    setSortedProtos(protos);
  }, [filters, prototypes, serialFilter, sortedBy]);

  const adjustSort = useCallback((key: string) => {
    let newSortedBy: SortParams | null;
    if (sortedBy && sortedBy.key === key) {
      if (sortedBy.asc) {
        newSortedBy = { key, asc: false };
      } else {
        newSortedBy = null;
      }
    } else {
      newSortedBy = { key, asc: true };
    }
    setSortedBy(newSortedBy);
  }, [sortedBy]);

  const setFilter = (filter: string, value: any) => {
    let newFilters = { ...filters };
    newFilters[filter] = value;
    setFilters(newFilters);
  }

  const resetFilters = () => {
    let newFilters = { ...filters };
    Object.keys(filters).map((key) => newFilters[key] = "Not set");
    setFilters(newFilters);
    setSerialFilter("");
  }

  const openProtoPage = (evnt: React.MouseEvent, proto: Prototype) => {
    const protoUrl = `/design/${proto.design.id}/proto/${proto.serial}`;
    if (evnt.button === 1) {
      window.open(protoUrl, "_blank")?.focus();
    } else {
      history.push(protoUrl);
    }
  };

  return (<div className="flex flex-col gap-2 mt-2">
    <Collapsible header="Filter">
      <div className="flex flex-col gap-2">
        <TextInput
          value={serialFilter}
          onChange={setSerialFilter}>Serial</TextInput>
        <SelectInput
          setId={(val: string) => setFilter("design.name", val)}
          options={["Not set"].concat(Array.from(new Set(prototypes.map((proto) => proto.design.name))).sort())}
          value={filters["design.name"]}>Design</SelectInput>
        <SelectInput
          setId={(val: string) => setFilter("revision.name", val)}
          options={["Not set"].concat(Array.from(new Set(prototypes.map((proto) => proto.revision.name))).sort())}
          value={filters["revision.name"]}>Revision</SelectInput>
        <SelectInput
          setId={(val: string) => setFilter("location.name", val)}
          options={["Not set"].concat(Array.from(new Set(prototypes.map((proto) => proto.location?.name || "Invalid location"))).sort())}
          value={filters["location.name"]}>Location</SelectInput>
        <SelectInput
          setId={(val: string) => setFilter("status.name", val)}
          options={["Not set"].concat(Array.from(new Set(prototypes.map((proto) => proto.status?.name || "Invalid status"))).sort())}
          value={filters["status.name"]}>Status</SelectInput>
        <LightButton onClick={resetFilters}>Reset Filters</LightButton>
      </div>
    </Collapsible >
    <div className="overflow-x-auto">
      <table className="w-full">
        <thead>
          <tr className="font-semibold cursor-pointer">
            <td className="hover:bg-green-100 pr-2"><SortToggle id="design.name" sortedBy={sortedBy} onClick={adjustSort}>Design</SortToggle></td>
            <td className="hover:bg-green-100 pr-2"><SortToggle id="revision.name" sortedBy={sortedBy} onClick={adjustSort}>Revision</SortToggle></td>
            <td className="hover:bg-green-100 pr-2"><SortToggle id="serial" sortedBy={sortedBy} onClick={adjustSort}>Serial</SortToggle></td>
            <td className="hover:bg-green-100 pr-2"><SortToggle id="location.name" sortedBy={sortedBy} onClick={adjustSort}>Location</SortToggle></td>
            <td className="hover:bg-green-100 pr-2"><SortToggle id="status.name" sortedBy={sortedBy} onClick={adjustSort}>Status</SortToggle></td>
            <td className="hover:bg-green-100"><SortToggle id="manufactured" sortedBy={sortedBy} onClick={adjustSort}>Manufactured</SortToggle></td>
          </tr>
        </thead>
        <tbody>
          {sortedProtos.length > 0
            ? sortedProtos.map((proto: Prototype, idx: number) => (
              <tr
                key={idx}
                className="hover:bg-green-100 cursor-pointer"
                onClick={(evnt => openProtoPage(evnt, proto))}
                onAuxClick={(evnt => openProtoPage(evnt, proto))}>
                <td>{proto.design.name}</td>
                <td>{proto.revision.name || 'Not set'}</td>
                <td>{proto.serial}</td>
                <td>{proto.location?.name}</td>
                <td>{proto.status?.name}</td>
                <td>{new Date(proto.manufactured).toLocaleDateString()}</td>
              </tr>
            ))
            : <tr><td colSpan={6} className="text-center py-4">No prototypes found that match the current filters!</td></tr>}
        </tbody>
      </table>
    </div>
  </div >);
}

const setComponents = (result: { data: any }, componentSetter: Function, idSetter: Function) => {
  const data = result.data;
  componentSetter(data);
  idSetter(data.length > 0 ? data[0].id : '');
}

const ManufactureDialog = function ({ onClose, onSuccess }: DialogProps) {
  let [isOpen, setIsOpen] = useState(true);
  let [startedLoading, setStartedLoading] = useState(false);
  let [error, setError] = useState<OptionalString>(null);
  let [manufacturing, setManufacturing] = useState(false);
  let [serial, setSerial] = useState('');
  let [quantity, setQuantity] = useState('1');
  let [manufactured, setManufactured] = useState(new Date());
  let [notes, setNotes] = useState('');

  let [designCreate, setDesignCreate] = useState(true);
  let [designId, setDesignId] = useState<number | null>(null);
  let [designValue, setDesignValue] = useState('');
  let [designs, setDesigns] = useState<Array<Design>>([]);
  let [revisionCreate, setRevisionCreate] = useState(true);
  let [revisionId, setRevisionId] = useState<number | null>(null);
  let [revisionValue, setRevisionValue] = useState('');
  let [revisions, setRevisions] = useState<Array<Revision>>([]);
  let [locationCreate, setLocationCreate] = useState(true);
  let [locationId, setLocationId] = useState<number | null>(null);
  let [locationValue, setLocationValue] = useState('');
  let [locations, setLocations] = useState<Array<Location>>([]);
  let [statusCreate, setStatusCreate] = useState(true);
  let [statusId, setStatusId] = useState<number | null>(null);
  let [statusValue, setStatusValue] = useState('');
  let [statuses, setStatuses] = useState<Array<Status>>([]);

  useEffect(() => {
    if (!startedLoading) {
      setStartedLoading(true);
      axios.get("/designs/")
        .then((res) => setComponents(res, setDesigns, setDesignId))
        .catch((err) => setError(errorToString(err)));
      axios.get("/locations/")
        .then((res) => setComponents(res, setLocations, setLocationId))
        .catch((err) => setError(errorToString(err)));
      axios.get("/statuses/")
        .then((res) => setComponents(res, setStatuses, setStatusId))
        .catch((err) => setError(errorToString(err)));
    }
  }, [startedLoading]);

  useEffect(() => {
    if (designId && !designCreate) {
      axios.get(`/revisions/?design_id=${designId}`)
        .then((res) => setComponents(res, setRevisions, setRevisionId))
        .catch((err) => setError(errorToString(err)));
    } else {
      setRevisions([]);
      setRevisionId(null);
      setRevisionCreate(true);
    }
  }, [designId, designCreate]);

  const close = useCallback((success = false) => {
    setIsOpen(false);
    if (success && onSuccess) {
      onSuccess();
    }
    onClose();
  }, [onClose, onSuccess]);

  const validateForm = function () {
    let err = null;
    const qty = parseInt(quantity);
    const match = /\d+$/.exec(serial);
    const result = match !== null ? match[0] : null;

    if (!serial) err = "Initial serial must be set";
    else if (!manufactured) err = "Must set date of manufacture";
    else if (isNaN(qty) || qty < 1) err = "Quantity must be a number greater than 0";
    else if (result === null || 10 ** result.length - parseInt(result) < qty) {
      let err = `Initial serial must end with enough digits to allow for manufacture of ${qty} prototypes`;
      if (result !== null) {
        err += ` (from ${result} to ${'9'.repeat(result.length)} only allows for ${10 ** result.length - parseInt(result)} prototypes)`;
      }
      return err
    }
    else if (designCreate && !designValue) err = "Must set a name for the Design to be created";
    else if (designCreate && designs.map((design) => design.name).includes(designValue)) err = "Design name already exists";
    else if (!designCreate && !designId) err = "Must select an existing design to use if not creating one";
    else if (revisionCreate && !revisionValue) err = "Must set a name for the Revision to be created";
    else if (revisionCreate && revisions.map((revision) => revision.name).includes(revisionValue)) err = "Revision name already exists";
    else if (!revisionCreate && !revisionId) err = "Must select an existing revision to use if not creating one";
    else if (locationCreate && !locationValue) err = "Must set a name for the Location to be created";
    else if (locationCreate && locations.map((location) => location.name).includes(locationValue)) err = "Location name already exists";
    else if (!locationCreate && !locationId) err = "Must select an existing location to use if not creating one";
    else if (statusCreate && !statusValue) err = "Must set a name for the Status to be created";
    else if (statusCreate && statuses.map((status) => status.name).includes(statusValue)) err = "Status name already exists";
    else if (!statusCreate && !statusId) err = "Must select an existing status to use if not creating one";

    return err; // Validation successful
  }

  const prepareForManufacture = function () {
    setError(null);
    const err = validateForm();
    if (err) {
      setError(err);
      return;
    }

    if (designCreate) createDesign();
    if (!designCreate && revisionCreate) createRevision(designId);
    if (locationCreate) createLocation();
    if (statusCreate) createStatus();
    setManufacturing(true);
  }

  const createComponent = function (
    url: string,
    name: string,
    components: Array<any>,
    setComponents: Function,
    idSetCb: Function,
    createSetCb: Function,
    finalCb?: Function,
  ) {
    axios.post(url, { name })
      .then((res) => {
        const component = res.data;
        setComponents(components.concat(component));
        idSetCb(component.id);
        createSetCb(false);
        if (finalCb) {
          finalCb(component);
        }
      })
      .catch((err) => {
        setManufacturing(false);
        setError(errorToString(err));
      });
  }

  const createDesign = function () {
    createComponent("/designs/", designValue, designs, setDesigns, setDesignId, setDesignCreate, (comp: Design) => createRevision(comp.id));
  }

  const createRevision = function (id: number | null) {
    if (id === null) {
      console.error("Attempting to create revision for design with id 'null'");
      return;
    }

    createComponent(
      `/revisions/?design_id=${id}`, revisionValue, revisions, setRevisions, setRevisionId, setRevisionCreate
    );
  }

  const createLocation = function () {
    createComponent("/locations/", locationValue, locations, setLocations, setLocationId, setLocationCreate);
  }

  const createStatus = function () {
    createComponent("/statuses/", statusValue, statuses, setStatuses, setStatusId, setStatusCreate);
  }

  const manufacture = useCallback(() => {
    if (designCreate || revisionCreate || locationCreate || statusCreate) {
      return;  // Not yet finished creating components
    }
    setManufacturing(false);

    const manufacturingData = {
      serial,
      quantity,
      manufactured: manufactured.toISOString().split("T")[0],
      notes,
      design_id: designId,
      revision_id: revisionId,
      location_id: locationId,
      status_id: statusId
    };
    axios.post("/prototypes/", manufacturingData)
      .then(() => onSuccess && onSuccess())
      .then(() => close(true))
      .catch((err) => setError(`Submission error: ${errorToString(err)}`));
  }, [close, designCreate, designId, locationCreate, locationId, manufactured, notes, onSuccess, quantity, revisionCreate, revisionId, serial, statusCreate, statusId]);

  useEffect(() => {
    if (manufacturing) {
      manufacture();
    }
  }, [designCreate, revisionCreate, locationCreate, statusCreate, manufacturing, manufacture]);

  return (
    <FormModal
      isOpen={isOpen}
      loading={manufacturing}
      onClose={close}
      onOk={prepareForManufacture}
      okButton="Manufacture"
      title="Manufacture Prototypes"
      description="Use the following form to track new prototypes."
      error={error} >
      <TextInput value={serial} onChange={setSerial} maxLength={100}>
        Initial Serial:
      </TextInput>
      <NumericInput value={quantity} onChange={setQuantity}>
        Quantity:
      </NumericInput>
      <DateInput selected={manufactured} onChange={setManufactured}>
        Date Manufactured:
      </DateInput>
      <TextAreaInput value={notes} onChange={setNotes} rows={2} maxLength={1000}>
        Batch Notes:
      </TextAreaInput>
      <SelectOrCreateInput
        name="design"
        setCreate={setDesignCreate}
        setId={setDesignId}
        setValue={setDesignValue}
        selectOptions={designs}
        selected={getObjById(designs, designId)}
        create={designCreate}
        maxLength={100} >
        Design:
      </SelectOrCreateInput>
      <SelectOrCreateInput
        name="revision"
        setCreate={setRevisionCreate}
        setId={setRevisionId}
        setValue={setRevisionValue}
        selectOptions={revisions}
        selected={getObjById(revisions, revisionId)}
        create={revisionCreate}
        maxLength={20} >
        Revision:
      </SelectOrCreateInput>
      <SelectOrCreateInput
        name="location"
        setCreate={setLocationCreate}
        setId={setLocationId}
        setValue={setLocationValue}
        selectOptions={locations}
        selected={getObjById(locations, locationId)}
        create={locationCreate}
        maxLength={100} >
        Location:
      </SelectOrCreateInput>
      <SelectOrCreateInput
        name="status"
        setCreate={setStatusCreate}
        setId={setStatusId}
        setValue={setStatusValue}
        selectOptions={statuses}
        selected={getObjById(statuses, statusId)}
        create={statusCreate}
        maxLength={100} >
        Status:
      </SelectOrCreateInput>
    </FormModal>
  );
}

const DesignDialog = function ({ onClose, onSuccess }: DialogProps) {
  let [isOpen, setIsOpen] = useState(true);

  const close = (success: boolean) => {
    if (success && onSuccess) {
      onSuccess();
    }
    setIsOpen(false);
    onClose();
  }

  return (
    <SplitModal
      isOpen={isOpen}
      onClose={close}
      title="Manage Designs"
      sections={[{
        title: "Create / Modify Design",
        body: <ModifyDesignSection onClose={close} onSuccess={() => close(true)} />,
      },
      {
        title: "Delete Design",
        body: <DeleteDesignSection onClose={close} onSuccess={() => close(true)} />,
      },]} />
  )
}

const ModifyDesignSection = function ({ onClose, onSuccess }: DialogProps) {
  let [startedLoading, setStartedLoading] = useState(false);
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState<OptionalString>(null);
  let [designCreate, setDesignCreate] = useState(true);
  let [designId, setDesignId] = useState(null);
  let [designValue, setDesignValue] = useState('');
  let [designs, setDesigns] = useState<Array<Design>>([]);
  let [name, setName] = useState('');
  let [description, setDescription] = useState('');

  useEffect(() => {
    if (!startedLoading) {
      setStartedLoading(true);
      axios.get("/designs/")
        .then((res) => {
          setDesigns(res.data);
          setDesignId(res.data.length > 0 ? res.data[0].id : null);
        })
        .catch((err) => setError(errorToString(err)));
    }
  }, [startedLoading]);

  useEffect(() => {
    if (designCreate) {
      setName(designValue);
      setDescription("");
    } else {
      const design: Design = getObjById(designs, designId);
      setName(design ? design.name : '');
      setDescription(design && design.description ? design.description : '');
    }
  }, [designs, designCreate, designId, designValue])

  const close = useCallback((success = false) => {
    if (success && onSuccess) {
      onSuccess();
    }
    onClose();
  }, [onClose, onSuccess]);

  const onOk = () => {
    if (designCreate) {
      createDesign();
    } else {
      updateDesign();
    }
  }

  const createDesign = () => {
    if (designs.map((design) => design.name).includes(designValue)) {
      setError(`Design named '${name}' already exists`);
      return;
    }

    setLoading(true);
    axios.post("/designs/", { name, description })
      .then(() => close(true))
      .catch((err) => {
        setError(errorToString(err));
        setLoading(false);
      });
  }

  const updateDesign = () => {
    const oldDesign: Design = getObjById(designs, designId);
    if (!oldDesign) {
      setError("Invalid design ID");
      return;
    }

    let newDesign = { ...oldDesign };
    newDesign.name = name;
    newDesign.description = description;
    setLoading(true);

    axios.put("/designs/", newDesign)
      .then(() => close(true))
      .catch((err) => {
        setError(errorToString(err));
        setTimeout(() => setLoading(false), 1000);
      });
  }

  return (
    <FormModalSection
      description="Use the following form to create or update designs that prototypes can be manufactured from."
      error={error}
      onClose={close}
      onOk={onOk}
      okButton={designCreate ? "Create" : "Update"}
      loading={loading}>
      <SelectOrCreateInput
        name="design"
        setCreate={setDesignCreate}
        setId={setDesignId}
        setValue={setDesignValue}
        selectOptions={designs}
        selected={getObjById(designs, designId)}
        create={designCreate}
        maxLength={100} >
        Design to change:
      </SelectOrCreateInput>
      <TextInput value={name} onChange={setName} disabled={designCreate} maxLength={100}>New name:</TextInput>
      <TextAreaInput value={description} onChange={setDescription} rows={2} maxLength={1000}>Description:</TextAreaInput>
    </FormModalSection>
  );
}

const DeleteDesignSection = function ({ onClose, onSuccess }: DialogProps) {
  let [startedLoading, setStartedLoading] = useState(false);
  let [loaded, setLoaded] = useState(false);
  let [deleting, setDeleting] = useState(false);
  let [error, setError] = useState<OptionalString>(null);
  let [verification, setVerification] = useState('');
  let [designId, setDesignId] = useState<number>(0);
  let [designs, setDesigns] = useState<Array<Design>>([]);
  const confirmation = "CONFIRM";
  const deleteText = "Delete";

  useEffect(() => {
    if (!startedLoading) {
      setStartedLoading(true);
      axios.get("/designs/")
        .then((res) => setComponents(res, setDesigns, setDesignId))
        .catch((err) => setError(errorToString(err)))
        .finally(() => setLoaded(true));
    }
  }, [startedLoading]);

  const close = (success = false) => {
    if (success) {
      if (onSuccess) {
        onSuccess();
      }
    }
    onClose();
  }

  const triggerDelete = () => {
    if (confirmation !== verification) {
      setError(`Type ${confirmation} in the text box before clicking ${deleteText} to proceed`);
      return;
    }

    if (designId === null) {
      setError(`No design selected`);
      return;
    }

    setDeleting(true);
    axios.delete(`/designs/${designId}`)
      .then(() => close(true))
      .catch((err) => {
        setError(`Deletion error: ${errorToString(err)}`);
        setDeleting(false);
      });
  }

  return (
    <FormModalSection
      onClose={close}
      onOk={triggerDelete}
      okButton={deleteText}
      description={`This will permanently delete design '${getObjById(designs, designId)?.name}' from your account, along with any information associated with it. If you are sure this is what you want to do, type ${confirmation} in the box below and click ${deleteText}.`}
      error={error}
      loading={deleting} >
      {!loaded ? <p>Loading…</p> : (
        <>
          <SelectInput value={designId.toString()} options={designs} setId={setDesignId}>Design:</SelectInput>
          <TextInput value={verification} onChange={setVerification}>
            {`Type ${confirmation} to confirm deletion:`}
          </TextInput>
        </>
      )}
    </FormModalSection>
  );
}

const RevisionDialog = function ({ onClose, onSuccess }: DialogProps) {
  let [isOpen, setIsOpen] = useState(true);

  const close = (success: boolean) => {
    if (success && onSuccess) {
      onSuccess();
    }
    setIsOpen(false);
    onClose();
  }

  return (
    <SplitModal
      isOpen={isOpen}
      onClose={close}
      title="Manage Revisions"
      sections={[{
        title: "Create / Modify Revision",
        body: <ModifyRevisionSection onClose={close} onSuccess={() => close(true)} />,
      },
      {
        title: "Delete Revision",
        body: <DeleteRevisionSection onClose={close} onSuccess={() => close(true)} />,
      },]} />
  )
}

const ModifyRevisionSection = function ({ onClose, onSuccess }: DialogProps) {
  let [startedLoading, setStartedLoading] = useState(false);
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState<OptionalString>(null);
  let [designId, setDesignId] = useState(null);
  let [designs, setDesigns] = useState<Array<Design>>([]);
  let [revisionCreate, setRevisionCreate] = useState(true);
  let [revisionId, setRevisionId] = useState(null);
  let [revisionValue, setRevisionValue] = useState('');
  let [revisions, setRevisions] = useState<Array<Revision>>([]);
  let [name, setName] = useState('');
  let [notes, setNotes] = useState('');

  useEffect(() => {
    if (!startedLoading) {
      setStartedLoading(true);
      axios.get("/designs/")
        .then((res) => {
          setDesigns(res.data);
          setDesignId(res.data.length > 0 ? res.data[0].id : null);
        })
        .catch((err) => setError(errorToString(err)));
    }
  }, [startedLoading]);

  useEffect(() => {
    if (designId === null) {
      return;
    }

    axios.get(`/revisions/?design_id=${designId}`)
      .then((res) => {
        setRevisions(res.data);
        setRevisionId(res.data.length > 0 ? res.data[0].id : null);
      })
      .catch((err) => setError(errorToString(err)));
  }, [designId])

  useEffect(() => {
    if (revisionCreate) {
      setName(revisionValue);
      setNotes("");
    } else {
      const revision: Revision = getObjById(revisions, revisionId);
      setName(revision ? revision.name : '');
      setNotes(revision && revision.notes ? revision.notes : '');
    }
  }, [revisions, revisionCreate, revisionId, revisionValue])

  const close = useCallback((success = false) => {
    if (success && onSuccess) {
      onSuccess();
    }
    onClose();
  }, [onClose, onSuccess]);

  const onOk = () => {
    if (revisionCreate) {
      createRevision();
    } else {
      updateRevision();
    }
  }

  const createRevision = () => {
    if (designs.map((revision) => revision.name).includes(revisionValue)) {
      setError(`Revision named '${name}' already exists`);
      return;
    }

    setLoading(true);
    axios.post(`/revisions/?design_id=${designId}`, { name, notes })
      .then(() => close(true))
      .catch((err) => {
        setError(errorToString(err));
        setLoading(false);
      });
  }

  const updateRevision = () => {
    const oldRevision: Revision = getObjById(revisions, revisionId);
    if (!oldRevision) {
      setError("Invalid revision ID");
      return;
    }

    setLoading(true);
    let newRevision = { ...oldRevision };
    newRevision.name = name;
    newRevision.notes = notes;
    axios.put("/revisions/", newRevision)
      .then(() => close(true))
      .catch((err) => {
        setError(errorToString(err));
        setLoading(false);
      });
  }

  return (
    <FormModalSection
      onClose={close}
      onOk={onOk}
      okButton={revisionCreate ? "Create" : "Update"}
      description="Use the following form to create or update revisions of designs."
      error={error}
      loading={loading} >
      <SelectInput value={designId} options={designs} setId={setDesignId}>Design:</SelectInput>
      <SelectOrCreateInput
        name="revision"
        setCreate={setRevisionCreate}
        setId={setRevisionId}
        setValue={setRevisionValue}
        selectOptions={revisions}
        selected={getObjById(revisions, revisionId)}
        create={revisionCreate}
        maxLength={20} >
        Revision to change:
      </SelectOrCreateInput>
      <TextInput value={name} onChange={setName} disabled={revisionCreate} maxLength={20}>New name:</TextInput>
      <TextAreaInput value={notes} onChange={setNotes} rows={2} maxLength={1000}>Notes:</TextAreaInput>
    </FormModalSection>
  );
}

const DeleteRevisionSection = function ({ onClose, onSuccess }: DialogProps) {
  let [startedLoading, setStartedLoading] = useState(false);
  let [loaded, setLoaded] = useState(false);
  let [deleting, setDeleting] = useState(false);
  let [error, setError] = useState<OptionalString>(null);
  let [verification, setVerification] = useState('');
  let [designId, setDesignId] = useState<number | null>(null);
  let [designs, setDesigns] = useState<Array<Design>>([]);
  let [revisionId, setRevisionId] = useState<number | null>(null);
  let [revisions, setRevisions] = useState<Array<Revision>>([]);
  const confirmation = "CONFIRM";
  const deleteText = "Delete";

  useEffect(() => {
    if (!startedLoading) {
      setStartedLoading(true);
      axios.get("/designs/")
        .then((res) => {
          setDesigns(res.data);
          setDesignId(res.data.length > 0 ? res.data[0].id : null);
        })
        .catch((err) => setError(errorToString(err)))
        .finally(() => setLoaded(true));
    }
  }, [startedLoading]);

  useEffect(() => {
    if (designId === null) {
      return;
    }

    axios.get(`/revisions/?design_id=${designId}`)
      .then((res) => {
        setRevisions(res.data);
        setRevisionId(res.data.length > 0 ? res.data[0].id : null);
      })
      .catch((err) => setError(errorToString(err)));
  }, [designId])

  const close = (success = false) => {
    if (success) {
      if (onSuccess) {
        onSuccess();
      }
    }
    onClose();
  }

  const triggerDelete = () => {
    if (confirmation !== verification) {
      setError(`Type ${confirmation} in the text box before clicking ${deleteText} to proceed`);
      return;
    }

    if (designId === null) {
      setError(`No design selected`);
      return;
    }

    if (revisionId === null) {
      setError(`No revision selected`);
      return;
    }

    setDeleting(true);
    axios.delete(`/revisions/${revisionId}`)
      .then(() => close(true))
      .catch((err) => {
        setError(`Deletion error: ${errorToString(err)}`);
        setDeleting(false);
      });
  }

  return (
    <FormModalSection
      onClose={close}
      onOk={triggerDelete}
      okButton={deleteText}
      description={`This will permanently delete revision '${getObjById(revisions, revisionId)?.name}' from design '${getObjById(designs, designId)?.name}' from your account, along with any information associated with it. If you are sure this is what you want to do, type ${confirmation} in the box below and click ${deleteText}.`}
      error={error}
      loading={deleting}>
      {!loaded ? <p>Loading…</p> : (
        <>
          <SelectInput value={designId?.toString() || ""} options={designs} setId={setDesignId}>Design:</SelectInput>
          <SelectInput value={revisionId?.toString() || ""} options={revisions} setId={setRevisionId}>Revision:</SelectInput>
          <TextInput value={verification} onChange={setVerification}>
            {`Type ${confirmation} to confirm deletion:`}
          </TextInput>
        </>
      )}
    </FormModalSection>
  );
}

const ModDialog = function ({ onClose, onSuccess }: DialogProps) {
  let [isOpen, setIsOpen] = useState(true);

  const close = (success: boolean) => {
    if (success && onSuccess) {
      onSuccess();
    }
    setIsOpen(false);
    onClose();
  }

  return (
    <SplitModal
      isOpen={isOpen}
      onClose={close}
      title="Manage Mods"
      sections={[{
        title: "Create / Modify Mod",
        body: <ModifyModSection onClose={close} onSuccess={() => close(true)} />,
      },
      {
        title: "Delete Mod",
        body: <DeleteModSection onClose={close} onSuccess={() => close(true)} />,
      },]} />
  )
}

const ModifyModSection = function ({ onClose, onSuccess }: DialogProps) {
  let [startedLoading, setStartedLoading] = useState(false);
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState<OptionalString>(null);
  let [designId, setDesignId] = useState(null);
  let [designs, setDesigns] = useState<Array<Design>>([]);
  let [revisionId, setRevisionId] = useState(null);
  let [revisions, setRevisions] = useState<Array<Revision>>([]);
  let [modCreate, setModCreate] = useState(true);
  let [modId, setModId] = useState(null);
  let [modValue, setModValue] = useState('');
  let [mods, setMods] = useState<Array<Revision>>([]);
  let [name, setName] = useState('');
  let [description, setDescription] = useState('');

  useEffect(() => {
    if (!startedLoading) {
      setStartedLoading(true);
      axios.get("/designs/")
        .then((res) => {
          setDesigns(res.data);
          setDesignId(res.data.length > 0 ? res.data[0].id : null);
        })
        .catch((err) => setError(errorToString(err)));
    }
  }, [startedLoading]);

  useEffect(() => {
    if (designId === null) {
      return;
    }

    axios.get(`/revisions/?design_id=${designId}`)
      .then((res) => {
        setRevisions(res.data);
        setRevisionId(res.data.length > 0 ? res.data[0].id : null);
      })
      .catch((err) => setError(errorToString(err)));
  }, [designId])

  useEffect(() => {
    if (revisionId === null) {
      return;
    }

    axios.get(`/mods/?revision_id=${revisionId}`)
      .then((res) => {
        setMods(res.data);
        setModId(res.data.length > 0 ? res.data[0].id : null);
      })
      .catch((err) => setError(errorToString(err)));
  }, [revisionId])

  useEffect(() => {
    if (modCreate) {
      setName(modValue);
      setDescription("");
    } else {
      const mod: Mod = getObjById(mods, modId);
      setName(mod ? mod.name : '');
      setDescription(mod && mod.description ? mod.description : '');
    }
  }, [mods, modCreate, modId, modValue])

  const close = useCallback((success = false) => {
    if (success && onSuccess) {
      onSuccess();
    }
    onClose();
  }, [onClose, onSuccess]);

  const onOk = () => {
    if (modCreate) {
      createMod();
    } else {
      updateMod();
    }
  }

  const createMod = () => {
    if (designs.map((mod) => mod.name).includes(modValue)) {
      setError(`Mod named '${name}' already exists`);
      return;
    }

    setLoading(true);
    axios.post(`/mods/?revision_id=${revisionId}`, { name, description })
      .then(() => close(true))
      .catch((err) => {
        setError(errorToString(err));
        setLoading(false);
      });
  }

  const updateMod = () => {
    const oldMod: Mod = getObjById(mods, modId);
    if (!oldMod) {
      setError("Invalid mod ID");
      return;
    }

    setLoading(true);
    let newMod = { ...oldMod };
    newMod.name = name;
    newMod.description = description;
    axios.put("/mods/", newMod)
      .then(() => close(true))
      .catch((err) => {
        setError(errorToString(err));
        setLoading(false);
      });
  }

  return (
    <FormModalSection
      onClose={close}
      onOk={onOk}
      okButton={modCreate ? "Create" : "Update"}
      description="Use the following form to create or update mods applicable to specific revisions of designs."
      error={error}
      loading={loading} >
      <SelectInput value={designId} options={designs} setId={setDesignId}>Design:</SelectInput>
      <SelectInput value={revisionId} options={revisions} setId={setRevisionId}>Revision:</SelectInput>
      <SelectOrCreateInput
        name="mod"
        setCreate={setModCreate}
        setId={setModId}
        setValue={setModValue}
        selectOptions={mods}
        selected={getObjById(mods, modId)}
        create={modCreate}
        maxLength={100} >
        Mod to change:
      </SelectOrCreateInput>
      <TextInput value={name} onChange={setName} disabled={modCreate} maxLength={100}>New name:</TextInput>
      <TextAreaInput value={description} onChange={setDescription} rows={2} maxLength={1000}>Description:</TextAreaInput>
    </FormModalSection>
  );
}

const DeleteModSection = function ({ onClose, onSuccess }: DialogProps) {
  let [startedLoading, setStartedLoading] = useState(false);
  let [loaded, setLoaded] = useState(false);
  let [deleting, setDeleting] = useState(false);
  let [error, setError] = useState<OptionalString>(null);
  let [verification, setVerification] = useState('');
  let [designId, setDesignId] = useState<number | null>(null);
  let [designs, setDesigns] = useState<Array<Design>>([]);
  let [revisionId, setRevisionId] = useState<number | null>(null);
  let [revisions, setRevisions] = useState<Array<Revision>>([]);
  let [modId, setModId] = useState<number | null>(null);
  let [mods, setMods] = useState<Array<Revision>>([]);
  const confirmation = "CONFIRM";
  const deleteText = "Delete";

  useEffect(() => {
    if (!startedLoading) {
      setStartedLoading(true);
      axios.get("/designs/")
        .then((res) => {
          setDesigns(res.data);
          setDesignId(res.data.length > 0 ? res.data[0].id : null);
        })
        .catch((err) => setError(errorToString(err)))
        .finally(() => setLoaded(true));
    }
  }, [startedLoading]);

  useEffect(() => {
    if (designId === null) {
      return;
    }

    axios.get(`/revisions/?design_id=${designId}`)
      .then((res) => {
        setRevisions(res.data);
        setRevisionId(res.data.length > 0 ? res.data[0].id : null);
      })
      .catch((err) => setError(errorToString(err)));
  }, [designId])

  useEffect(() => {
    if (revisionId === null) {
      return;
    }

    axios.get(`/mods/?revision_id=${revisionId}`)
      .then((res) => {
        setMods(res.data);
        setModId(res.data.length > 0 ? res.data[0].id : null);
      })
      .catch((err) => setError(errorToString(err)));
  }, [revisionId])

  const close = (success = false) => {
    if (success) {
      if (onSuccess) {
        onSuccess();
      }
    }
    onClose();
  }

  const triggerDelete = () => {
    if (confirmation !== verification) {
      setError(`Type ${confirmation} in the text box before clicking ${deleteText} to proceed`);
      return;
    }

    if (designId === null) {
      setError(`No design selected`);
      return;
    }

    if (revisionId === null) {
      setError(`No revision selected`);
      return;
    }

    if (modId === null) {
      setError(`No mod selected`);
      return;
    }

    setDeleting(true);
    axios.delete(`/mods/${modId}`)
      .then(() => close(true))
      .catch((err) => {
        setError(`Deletion error: ${errorToString(err)}`);
        setDeleting(false);
      });
  }

  return (
    <FormModalSection
      onClose={close}
      onOk={triggerDelete}
      okButton={deleteText}
      description={`This will permanently delete mod '${getObjById(revisions, revisionId)?.name}' from revision '${getObjById(revisions, revisionId)?.name}' of design '${getObjById(designs, designId)?.name}' from your account, along with any information associated with it. If you are sure this is what you want to do, type ${confirmation} in the box below and click ${deleteText}.`}
      error={error}
      loading={deleting} >
      {!loaded ? <p>Loading…</p> : (
        <>
          <SelectInput value={designId?.toString() || ""} options={designs} setId={setDesignId}>Design:</SelectInput>
          <SelectInput value={revisionId?.toString() || ""} options={revisions} setId={setRevisionId}>Revision:</SelectInput>
          <SelectInput value={modId?.toString() || ""} options={mods} setId={setModId}>Mod:</SelectInput>
          <TextInput value={verification} onChange={setVerification}>
            {`Type ${confirmation} to confirm deletion:`}
          </TextInput>
        </>
      )}
    </FormModalSection>
  );
}

const LocationDialog = function ({ onClose, onSuccess }: DialogProps) {
  let [isOpen, setIsOpen] = useState(true);

  const close = (success: boolean) => {
    if (success && onSuccess) {
      onSuccess();
    }
    setIsOpen(false);
    onClose();
  }

  return (
    <SplitModal
      isOpen={isOpen}
      onClose={close}
      title="Manage Locations"
      sections={[{
        title: "Create / Modify Location",
        body: <ModifyLocationSection onClose={close} onSuccess={() => close(true)} />,
      },
      {
        title: "Delete Location",
        body: <DeleteLocationSection onClose={close} onSuccess={() => close(true)} />,
      },]} />
  )
}

const ModifyLocationSection = function ({ onClose, onSuccess }: DialogProps) {
  let [startedLoading, setStartedLoading] = useState(false);
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState<OptionalString>(null);
  let [locationCreate, setLocationCreate] = useState(true);
  let [locationId, setLocationId] = useState(null);
  let [locationValue, setLocationValue] = useState('');
  let [locations, setLocations] = useState<Array<Location>>([]);
  let [name, setName] = useState('');
  let [notes, setNotes] = useState('');
  let [inHouse, setInHouse] = useState(true);

  useEffect(() => {
    if (!startedLoading) {
      setStartedLoading(true);
      axios.get("/locations/")
        .then((res) => {
          setLocations(res.data);
          setLocationId(res.data.length > 0 ? res.data[0].id : null);
        })
        .catch((err) => setError(errorToString(err)));
    }
  }, [startedLoading]);

  useEffect(() => {
    if (locationCreate) {
      setName(locationValue);
      setNotes("");
      setInHouse(true);
    } else {
      const location: Location = getObjById(locations, locationId);
      setName(location ? location.name : '');
      setNotes(location && location.notes ? location.notes : '');
      setInHouse(location && location.in_house !== null ? location.in_house : true);
    }
  }, [locations, locationCreate, locationId, locationValue])

  const close = useCallback((success = false) => {
    if (success && onSuccess) {
      onSuccess();
    }
    onClose();
  }, [onClose, onSuccess]);

  const onOk = () => {
    if (locationCreate) {
      createLocation();
    } else {
      updateLocation();
    }
  }

  const createLocation = () => {
    if (locations.map((location) => location.name).includes(locationValue)) {
      setError(`Location named '${name}' already exists`);
      return;
    }

    setLoading(true);
    axios.post("/locations/", { name, notes, in_house: inHouse })
      .then(() => close(true))
      .catch((err) => {
        setError(errorToString(err))
        setLoading(false);
      });
  }

  const updateLocation = () => {
    const oldLocation: Location = getObjById(locations, locationId);
    if (!oldLocation) {
      setError("Invalid location ID");
      return;
    }

    setLoading(true);
    let newLocation = { ...oldLocation };
    newLocation.name = name;
    newLocation.notes = notes;
    newLocation.in_house = inHouse;
    axios.put("/locations/", newLocation)
      .then(() => close(true))
      .catch((err) => {
        setError(errorToString(err))
        setLoading(false);
      });
  }

  return (
    <FormModalSection
      onClose={close}
      onOk={onOk}
      okButton={locationCreate ? "Create" : "Update"}
      description="Use the following form to create or update locations that can be set for prototypes."
      error={error}
      loading={loading} >
      <SelectOrCreateInput
        name="location"
        setCreate={setLocationCreate}
        setId={setLocationId}
        setValue={setLocationValue}
        selectOptions={locations}
        selected={getObjById(locations, locationId)}
        create={locationCreate}
        maxLength={100} >
        Location to change:
      </SelectOrCreateInput>
      <TextInput value={name} onChange={setName} disabled={locationCreate} maxLength={100}>New name:</TextInput>
      <TextAreaInput value={notes} onChange={setNotes} rows={2} maxLength={1000}>Notes:</TextAreaInput>
      <CheckboxInput value={inHouse} onChange={setInHouse}>In-house:</CheckboxInput>
    </FormModalSection>
  );
}

const DeleteLocationSection = function ({ onClose, onSuccess }: DialogProps) {
  let [startedLoading, setStartedLoading] = useState(false);
  let [loaded, setLoaded] = useState(false);
  let [deleting, setDeleting] = useState(false);
  let [error, setError] = useState<OptionalString>(null);
  let [verification, setVerification] = useState('');
  let [locationId, setLocationId] = useState<number>(0);
  let [locations, setLocations] = useState<Array<Design>>([]);
  const confirmation = "CONFIRM";
  const deleteText = "Delete";

  useEffect(() => {
    if (!startedLoading) {
      setStartedLoading(true);
      axios.get("/locations/")
        .then((res) => setComponents(res, setLocations, setLocationId))
        .catch((err) => setError(errorToString(err)))
        .finally(() => setLoaded(true));
    }
  }, [startedLoading]);

  const close = (success = false) => {
    if (success) {
      if (onSuccess) {
        onSuccess();
      }
    }
    onClose();
  }

  const triggerDelete = () => {
    if (confirmation !== verification) {
      setError(`Type ${confirmation} in the text box before clicking ${deleteText} to proceed`);
      return;
    }

    if (locationId === null) {
      setError(`No location selected`);
      return;
    }

    setDeleting(true);
    axios.delete(`/locations/${locationId}`)
      .then(() => close(true))
      .catch((err) => {
        setError(`Deletion error: ${errorToString(err)}`);
        setDeleting(false);
      });
  }

  return (
    <FormModalSection
      onClose={close}
      onOk={triggerDelete}
      okButton={deleteText}
      description={`This will permanently delete location '${getObjById(locations, locationId)?.name}' from your account, along with any information associated with it. If you are sure this is what you want to do, type ${confirmation} in the box below and click ${deleteText}.`}
      error={error}
      loading={deleting} >
      {!loaded ? <p>Loading…</p> : (
        <>
          <SelectInput value={locationId.toString()} options={locations} setId={setLocationId}>Location:</SelectInput>
          <TextInput value={verification} onChange={setVerification}>
            {`Type ${confirmation} to confirm deletion:`}
          </TextInput>
        </>
      )}
    </FormModalSection>
  );
}

const StatusDialog = function ({ onClose, onSuccess }: DialogProps) {
  let [isOpen, setIsOpen] = useState(true);

  const close = (success: boolean) => {
    if (success && onSuccess) {
      onSuccess();
    }
    setIsOpen(false);
    onClose();
  }

  return (
    <SplitModal
      isOpen={isOpen}
      onClose={close}
      title="Manage Statuses"
      sections={[{
        title: "Create / Modify Status",
        body: <ModifyStatusSection onClose={close} onSuccess={() => close(true)} />,
      },
      {
        title: "Re-order Statuses",
        body: <OrderStatusesSection onClose={close} onSuccess={() => close(true)} />,
      },
      {
        title: "Delete Status",
        body: <DeleteStatusSection onClose={close} onSuccess={() => close(true)} />,
      },]} />
  )
}

const ModifyStatusSection = function ({ onClose, onSuccess }: DialogProps) {
  let [startedLoading, setStartedLoading] = useState(false);
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState<OptionalString>(null);
  let [statusCreate, setStatusCreate] = useState(true);
  let [statusId, setStatusId] = useState(null);
  let [statusValue, setStatusValue] = useState('');
  let [statuses, setStatuses] = useState<Array<Status>>([]);
  let [name, setName] = useState('');
  let [notes, setNotes] = useState('');

  useEffect(() => {
    if (!startedLoading) {
      setStartedLoading(true);
      axios.get("/statuses/")
        .then((res) => {
          setStatuses(res.data);
          setStatusId(res.data.length > 0 ? res.data[0].id : null);
        })
        .catch((err) => setError(errorToString(err)));
    }
  }, [startedLoading]);

  useEffect(() => {
    if (statusCreate) {
      setName(statusValue);
      setNotes("");
    } else {
      const status: Status = getObjById(statuses, statusId);
      setName(status ? status.name : '');
      setNotes(status && status.notes ? status.notes : '');
    }
  }, [statuses, statusCreate, statusId, statusValue])

  const close = useCallback((success = false) => {
    if (success && onSuccess) {
      onSuccess();
    }
    onClose();
  }, [onClose, onSuccess]);

  const onOk = () => {
    if (statusCreate) {
      createStatus();
    } else {
      updateStatus();
    }
  }

  const createStatus = () => {
    if (statuses.map((status) => status.name).includes(statusValue)) {
      setError(`Status named '${name}' already exists`);
      return;
    }

    setLoading(true);
    axios.post("/statuses/", { name, notes })
      .then(() => close(true))
      .catch((err) => {
        setError(errorToString(err));
        setLoading(false);
      });
  }

  const updateStatus = () => {
    const oldStatus: Status = getObjById(statuses, statusId);
    if (!oldStatus) {
      setError("Invalid status ID");
      return;
    }

    setLoading(true);
    let newStatus = { ...oldStatus };
    newStatus.name = name;
    newStatus.notes = notes;
    axios.put("/statuses/", newStatus)
      .then(() => close(true))
      .catch((err) => {
        setError(errorToString(err));
        setLoading(false);
      });
  }

  return (
    <FormModalSection
      onClose={close}
      onOk={onOk}
      okButton={statusCreate ? "Create" : "Update"}
      description="Use the following form to create or update statuses that can be applied to prototypes."
      error={error}
      loading={loading} >
      <SelectOrCreateInput
        name="status"
        setCreate={setStatusCreate}
        setId={setStatusId}
        setValue={setStatusValue}
        selectOptions={statuses}
        selected={getObjById(statuses, statusId)}
        create={statusCreate}
        maxLength={100} >
        Status to change:
      </SelectOrCreateInput>
      <TextInput value={name} onChange={setName} disabled={statusCreate} maxLength={100}>New name:</TextInput>
      <TextAreaInput value={notes} onChange={setNotes} rows={2} maxLength={1000}>Notes:</TextAreaInput>
    </FormModalSection>
  );
}

const OrderStatusesSection = function ({ onClose, onSuccess }: DialogProps) {
  let [startedLoading, setStartedLoading] = useState(false);
  let [loaded, setLoaded] = useState(false);
  let [moving, setMoving] = useState(false);
  let [error, setError] = useState<OptionalString>(null);
  let [statuses, setStatuses] = useState<Array<Design>>([]);

  useEffect(() => {
    if (!startedLoading) {
      setStartedLoading(true);
      axios.get("/statuses/")
        .then((res) => setComponents(res, setStatuses, () => { }))
        .catch((err) => setError(errorToString(err)))
        .finally(() => setLoaded(true));
    }
  }, [startedLoading]);

  const close = (success = false) => {
    if (success) {
      if (onSuccess) {
        onSuccess();
      }
    }
    onClose();
  }

  const reorder = () => {
    setMoving(true);
    const data = { ids: statuses.map((status) => (status.id)) };
    axios.put(`/statuses/reorder`, data)
      .then(() => close(true))
      .catch((err) => {
        setError(`Update error: ${errorToString(err)}`);
        setMoving(false);
      });
  }

  const moveStatus = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragStatus = statuses[dragIndex]
      setStatuses(
        update(statuses, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragStatus],
          ],
        }),
      )
    },
    [statuses],
  )

  return (
    <ModalSection
      onClose={close}
      onOk={reorder}
      okButton="Re-order"
      description="Drag and drop the statuses into the desired order to represent your workflow"
      error={error}
      loading={moving} >
      {!loaded ? <p>Loading…</p> : (
        <DragList list={statuses} descriptor="name" move={moveStatus} />
      )}
    </ModalSection>
  );
}

const DeleteStatusSection = function ({ onClose, onSuccess }: DialogProps) {
  let [startedLoading, setStartedLoading] = useState(false);
  let [loaded, setLoaded] = useState(false);
  let [deleting, setDeleting] = useState(false);
  let [error, setError] = useState<OptionalString>(null);
  let [verification, setVerification] = useState('');
  let [statusId, setStatusId] = useState<number>(0);
  let [statuses, setStatuses] = useState<Array<Design>>([]);
  const confirmation = "CONFIRM";
  const deleteText = "Delete";

  useEffect(() => {
    if (!startedLoading) {
      setStartedLoading(true);
      axios.get("/statuses/")
        .then((res) => setComponents(res, setStatuses, setStatusId))
        .catch((err) => setError(errorToString(err)))
        .finally(() => setLoaded(true));
    }
  }, [startedLoading]);

  const close = (success = false) => {
    if (success) {
      if (onSuccess) {
        onSuccess();
      }
    }
    onClose();
  }

  const triggerDelete = () => {
    if (confirmation !== verification) {
      setError(`Type ${confirmation} in the text box before clicking ${deleteText} to proceed`);
      return;
    }

    if (statusId === null) {
      setError(`No status selected`);
      return;
    }

    setDeleting(true);
    axios.delete(`/statuses/${statusId}`)
      .then(() => close(true))
      .catch((err) => {
        setError(`Deletion error: ${errorToString(err)}`);
        setDeleting(false);
      });
  }

  return (
    <FormModalSection
      onClose={close}
      onOk={triggerDelete}
      okButton={deleteText}
      description={`This will permanently delete status '${getObjById(statuses, statusId)?.name}' from your account, along with any information associated with it. If you are sure this is what you want to do, type ${confirmation} in the box below and click ${deleteText}.`}
      error={error}
      loading={deleting} >
      {!loaded ? <p>Loading…</p> : (
        <>
          <SelectInput value={statusId.toString()} options={statuses} setId={setStatusId}>Status:</SelectInput>
          <TextInput value={verification} onChange={setVerification}>
            {`Type ${confirmation} to confirm deletion:`}
          </TextInput>
        </>
      )}
    </FormModalSection>
  );
}

const Dashboard = function () {
  let [initialLoad, setInitialLoad] = useState(true);
  let [loadingDashboard, setLoadingDashboard] = useState(true);
  let [prototypes, setPrototypes] = useState([]);
  let [error, setError] = useState<OptionalString>(null);
  let [showManufactureModal, setShowManufactureModal] = useState(false);
  let [showDesignModal, setShowDesignModal] = useState(false);
  let [showRevisionModal, setShowRevisionModal] = useState(false);
  let [showModsModal, setShowModsModal] = useState(false);
  let [showLocationModal, setShowLocationModal] = useState(false);
  let [showStatusModal, setShowStatusModal] = useState(false);

  const getPrototypes = function () {
    axios.get("/prototypes/me")
      .then((res) => setPrototypes(res.data))
      .catch((err) => setError(errorToString(err)))
      .finally(() => setLoadingDashboard(false));
  }

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
      getPrototypes();
    }
  }, [initialLoad]);

  let listOverride = null;
  if (loadingDashboard) {
    listOverride = <h1>…</h1>;
  } else if (error) {
    listOverride = <p className="my-2 p-2 border bg-yellow-200 border-yellow-300 rounded">{error}</p>;
  } else if (prototypes.length < 1) {
    listOverride = <p className="my-2">No prototypes found! Manufacture some prototypes to begin tracking.</p>
  }

  return (
    <div>
      <Helmet>
        <title>Dashboard | Proto Tracker</title>
        <meta name="description" content="Dashboard for displaying prototype status and locations." />
      </Helmet>
      <h1 className="text-2xl">Dashboard</h1>
      <div className="flex flex-col gap-1 md:flex-row md:gap-2">
        <StandardButton className="flex-1 font-semibold" onClick={() => setShowManufactureModal(true)}>
          Manufacture Prototypes
        </StandardButton>
        <StandardButton className="flex-1" onClick={() => setShowDesignModal(true)}>
          Manage Designs
        </StandardButton>
        <StandardButton className="flex-1" onClick={() => setShowRevisionModal(true)}>
          Manage Revisions
        </StandardButton>
        <StandardButton className="flex-1" onClick={() => setShowModsModal(true)}>
          Manage Mods
        </StandardButton>
        <StandardButton className="flex-1" onClick={() => setShowLocationModal(true)}>
          Manage Locations
        </StandardButton>
        <StandardButton className="flex-1" onClick={() => setShowStatusModal(true)}>
          Manage Statuses
        </StandardButton>
      </div>
      {showManufactureModal && <ManufactureDialog onClose={() => setShowManufactureModal(false)} onSuccess={getPrototypes} />}
      {showDesignModal && <DesignDialog onClose={() => setShowDesignModal(false)} onSuccess={getPrototypes} />}
      {showRevisionModal && <RevisionDialog onClose={() => setShowRevisionModal(false)} onSuccess={getPrototypes} />}
      {showModsModal && <ModDialog onClose={() => setShowModsModal(false)} onSuccess={getPrototypes} />}
      {showLocationModal && <LocationDialog onClose={() => setShowLocationModal(false)} onSuccess={getPrototypes} />}
      {showStatusModal && <StatusDialog onClose={() => setShowStatusModal(false)} onSuccess={getPrototypes} />}
      {listOverride || <PrototypeList prototypes={prototypes} />}
    </div>
  )
}

export default Dashboard;
