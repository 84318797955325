import { Link } from 'react-router-dom';
import { SmallSpinner } from './Spinners';

interface BaseButtonProps {
  className?: string,
  children?: string | JSX.Element,
  disabled?: boolean,
}

interface ButtonProps extends BaseButtonProps {
  onClick: Function,
  type?: "button" | "submit" | "reset",
  loading?: boolean,
}

interface LinkButtonProps extends BaseButtonProps {
  to: string,
}

function GenericButton(props: ButtonProps) {
  const onClick = props.onClick || (() => { });

  return (
    <button
      className={`relative px-2 border rounded ${props.disabled ? "cursor-default" : ""} ${props.className}`}
      onClick={() => onClick()}
      type={props.type || "button"}
      disabled={props.disabled || props.loading}>
      {props.loading && <SmallSpinner className="absolute inset-0" />}
      <div className={props.loading ? "invisible" : "visible"}>{props.children}</div>
    </button>
  );
}

export const StandardButton = (props: ButtonProps) => {
  return (
    <GenericButton
      {...props}
      className={`bg-green-100 hover:bg-green-200 border-green-600 py-2 ${props.className}`}>
      {props.children}
    </GenericButton>
  );
}

export const LightButton = (props: ButtonProps) => {
  return (
    <GenericButton
      {...props}
      className={`bg-gray-200 hover:bg-gray-300 border-gray-400 py-2 ${props.className}`}>
      {props.children}
    </GenericButton>
  );
}

export const SlimButton = (props: ButtonProps) => {
  return (
    <GenericButton
      {...props}
      className={`bg-gray-100 hover:bg-gray-200 border-gray-400 ${props.className}`}>
      {props.children}
    </GenericButton>
  );
}

export const WarningButton = (props: ButtonProps) => {
  return (
    <GenericButton
      {...props}
      className={`${props.disabled ? "text-gray-400 py-2 cursor-not-allowed" : "text-white hover:bg-red-700 border-red-800"} bg-red-600 ${props.className}`}>
      {props.children}
    </GenericButton>
  );
}

export const LinkButton = (props: LinkButtonProps) => {
  return (
    <Link
      to={props.to}
      className={`border mt-2 p-2 rounded bg-green-200 hover:bg-green-300 border-green-400 text-black no-underline inline-block ${props.className}`}>
      {props.children}
    </Link>
  )
}

export const SmallLinkButton = (props: LinkButtonProps) => {
  return (
    <Link
      to={props.to}
      className={`border p-1 rounded bg-blue-200 hover:bg-blue-300 border-blue-400 text-black no-underline inline-block ${props.className}`}>
      {props.children}
    </Link>
  )
}

export const DeleteButton = (props: ButtonProps) => {
  return (
    <GenericButton
      {...props}
      className={`border px-1 py-0 m-1 rounded bg-red-600 hover:bg-red-700 border-red-800 text-white no-underline inline-block ${props.className}`}>
      {props.children || "⨉"}
    </GenericButton>
  )
}
