import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router";

interface LogoutProps { onLogout: Function }

const Logout = function ({ onLogout }: LogoutProps) {
  const history = useHistory();
  let [loggingOut, setLoggingOut] = useState(false);

  useEffect(() => {
    if (!loggingOut) {
      setLoggingOut(true);
      onLogout();
      history.push("/");
    }
  }, [history, loggingOut, onLogout]);

  return (
    <div>
      <Helmet>
        <title>Logout | Proto Tracker</title>
        <meta name="description" content="Logout page for Proto Tracker." />
      </Helmet>
      <h1>Logging out <span className="inline animate-spin-slow">⁙</span></h1>
    </div>
  );
}

export default Logout;
