import axios from "axios";
import { useState } from "react";
import { Redirect, useHistory } from "react-router";
import { StandardButton, WarningButton } from "./components/Buttons";
import { CheckboxInput, TextInput } from "./components/Inputs";
import { errorToString, validateEmail } from "./js/helpers";
import { FormModal } from "./components/Modals";
import { Helmet } from "react-helmet-async";

interface SectionProps extends BasicProps {
  title: string,
}
const Section = ({ title, children, className, ...props }: SectionProps) => {
  return (
    <div {...props} className={`mt-4 flex flex-col gap-2 ${className}`}>
      <h2 className="text-xl font-semibold">{title}</h2>
      {children}
    </div>
  )
}

interface ResetModalProps {
  user: User,
  onClose: Function,
  onSuccess: Function,
  isOpen: boolean,
}
const ResetModal = ({ user, onClose, onSuccess, isOpen }: ResetModalProps) => {
  const [error, setError] = useState<OptionalString>(null);
  const [loading, setLoading] = useState(false);

  const resetPassword = () => {
    setError(null);
    setLoading(true);
    axios.post("/users/me/reset_password", { email: user.email })
      .then(() => onSuccess())
      .then(() => onClose())
      .catch((err) => {
        setError(`Password reset error: ${errorToString(err)}`);
        setLoading(false);
      });
  };

  return (
    <FormModal
      title="Reset Password"
      description={`This will send an email to ${user.email} with a password reset link that you can use to update your password. Proceed?`}
      isOpen={isOpen}
      onClose={onClose}
      onOk={resetPassword}
      okButton="Send Password Reset Email"
      error={error}
      loading={loading}>
      <></>
    </FormModal>
  )
}

interface AccountProps {
  user: User | null,
  onUserChange: Function,
}
const Account = function ({ user, onUserChange }: AccountProps) {
  const [error, setError] = useState<OptionalString>(null);
  const [success, setSuccess] = useState<OptionalString>(null);
  const [email, setEmail] = useState(user?.email);
  const [confirmEmail, setConfirmEmail] = useState(user?.email);
  const [name, setName] = useState(user?.full_name || "");
  const [showResetModal, setShowResetModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteVerification, setDeletVerification] = useState("");
  const history = useHistory();

  if (user === null) {
    return <Redirect to="/login" />
  }

  const DELETE_VERIFICATION = `I wish to permanently delete the account for ${user.email}`;

  const resetNotifications = (cb?: Function) => {
    setError(null);
    setSuccess(null);
    if (cb) {
      cb();
    }
  };

  const updateInformation = () => {
    resetNotifications();

    if (!email || !validateEmail(email)) {
      setError("Invalid email entered - please check and try again.");
      return;
    }

    if (email !== confirmEmail) {
      setError("Email confirmation must match initial email");
      return;
    }

    axios.put("/users/me", { email, full_name: name })
      .then(() => {
        setSuccess("Contact information successfully updated");
        setTimeout(onUserChange, 3000);
      })
      .catch((err) => setError(`Error updating account: ${errorToString(err)}`));
  };

  const deleteAccount = () => {
    if (!deleting) {
      setError("Deletion confirmation checkbox not checked");
      return;
    }
    if (deleteVerification !== DELETE_VERIFICATION) {
      setError("Deletion confirmation text incorrect");
      return;
    }

    axios.delete("/users/me")
      .then(() => {
        setSuccess("Account successfully deleted! Returning to home page...");
        setTimeout(() => {
          onUserChange();
          history.push("/");
        }, 3000);
      })
      .catch((err) => setError(`Error deleting account: ${errorToString(err)}`));
  }

  return (
    <div>
      <Helmet>
        <title>Account | Proto Tracker</title>
        <meta name="description" content="Account management page for Proto Tracker." />
      </Helmet>
      <h1 className="mb-4 text-2xl">Account Management</h1>
      {success && <p className="p-2 border bg-green-200 border-green-300 rounded">{success}</p>}
      {error && <p className="p-2 border bg-yellow-200 border-yellow-300 rounded">{error}</p>}
      <Section title="Contact Information">
        <TextInput value={name} onChange={setName} maxLength={255}>Name:</TextInput>
        <TextInput value={email} onChange={setEmail} maxLength={255}>Email:</TextInput>
        <TextInput value={confirmEmail} onChange={setConfirmEmail} maxLength={255}>Confirm Email:</TextInput>
        <StandardButton onClick={updateInformation}>Update Contact Information</StandardButton>
      </Section>
      <Section title="Security">
        {showResetModal ? <ResetModal isOpen={showResetModal} user={user} onClose={() => setShowResetModal(false)} onSuccess={() => setSuccess("Password reset email sent!")} /> : <></>}
        <StandardButton onClick={() => resetNotifications(() => setShowResetModal(true))}>
          Reset Password
        </StandardButton>
      </Section>
      <Section title="Account Deletion">
        <p>
          The following section allows you to delete your account.
          Note that any data associated with your account will be <b>permanently deleted</b>,
          including your contact information, as well as any prototype information you have.
        </p>
        <p className="font-semibold text-red-700">
          This process is NON-REVERSIBLE, so ensure that this is definitely what you want to
          do before proceeding.
        </p>
        <CheckboxInput value={deleting} onChange={setDeleting}>Delete Account?</CheckboxInput>
        <TextInput newLine={true} disabled={!deleting} value={deleteVerification} onChange={setDeletVerification}>{`Please enter "${DELETE_VERIFICATION}":`}</TextInput>
        <WarningButton
          onClick={deleteAccount}
          disabled={!deleting || deleteVerification !== DELETE_VERIFICATION}>
          Delete Account
        </WarningButton>
      </Section>
    </div>
  );
};

export default Account;
